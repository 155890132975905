<template>
	<div class="content-header spaced-content has-padding-bottom-1 has-margin-top-2 has-margin-bottom-075 has-bottom-divider is-flex is-flex-grow-0 is-align-items-flex-end">
		<VTitle
			:text="$t('fb.blockDetails.checkBlock.conditions')"
			:size="3"
			class="has-margin-bottom-0 has-margin-right-3"
		/>

		<VLink
			:text="$t('fb.blockDetails.checkBlock.addConditionGroup')"
			icon="plus"
			v-if="editMode"
			:hasUnderline="true"
			class="has-margin-right-3 has-margin-left-auto"
			@click.prevent.stop="addConditionGroup"
		/>
	</div>

	<div class="scrollable-content">
		<div class="spaced-content" v-if="conditionTables.length > 0">
			<template
				v-for="(conditionTableFields, key) in conditionTables"
				:key="key"
			>
				<div
					class="filter-group has-background-grey-light has-radius-large has-padding-075"
				>
					<div
						class="has-margin-bottom-1 is-flex is-flex-grow-0 is-align-items-center"
					>
						<VLink
							:text="$t('fb.blockDetails.checkBlock.addCondition')"
							icon="plus"
							v-if="editMode"
							:hasUnderline="true"
							class="has-margin-right-3"
							@click.prevent.stop="addConditionClicked(key)"
						/>

						<VButton
							v-if="editMode"
							@click.prevent.stop="deleteConditionGroup(key)"
							href=""
							:title="$t('general.delete')"
							:isTool="true"
							class="has-margin-left-auto"
							icon="delete"
							tabindex="-1"
						/>
					</div>
					<SortableDataTable
						:hoverable="false"
						:scrollable="false"
						:stickyHeader="false"
						tableClasses="table-without-padding"
						:data="conditionTableFields"
						:columns="conditionColumns"
						:emptyText="
							$t(
								'fb.blockDetails.checkBlock.conditionsTable.noResults'
							)
						"
					/>
				</div>

				<div v-if="key < conditionTables.length - 1">
					<VTitle
						class="has-text-centered is-uppercase has-margin-top-2 has-margin-bottom-2"
						:text="$t('general.or')"
						:size="3"
					/>
				</div>
			</template>
		</div>
		<div v-else class="has-text-centered">
			{{ $t('fb.blockDetails.checkBlock.noConditions') }}
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Field from '@assets/scripts/components/field';
import { getBlockConfig } from '@modules/FlowBuilder/components/block';
import {
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { getOperatorName } from '@modules/FlowBuilder/components/filtering';

export default {
	name: 'CheckBlockConfig',
	props: {
		description: {
			type: null,
		},
		preprocess: {
			type: null
		},
	},
	data: function () {
		return {
			blockUpdated: false,
		};
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate if flow is in Edit mode
			 */
			editMode: getStoreGetter('EDIT_MODE', 'FLOW'),
			/**
			 * Get block that is currently viewed/edited
			 */
			block: getStoreGetter('CURRENT_BLOCK', 'BLOCKS'),
		}),
		blockInput: function () {
			if (!this.block) return [];

			// get calculated input for block
			return this.$store.getters[
				getStoreGetter('BLOCK_INPUT', 'BLOCKS')
			](this.block.guid);
		},
		/**
		 * Get desctructured config of block
		 */
		config: function () {
			return getBlockConfig(this.block);
		},
		conditionColumns() {
			return [
				{
					label: this.$t(
						'fb.blockDetails.checkBlock.conditionsTable.columns.field'
					),
					field: 'field',
					sortable: false,
					headerClass: 'has-padding-left-0',
					cellClass: 'has-padding-left-0',
					width: '25%',
					default: this.$t('general.dash'),
				},
				{
					label: this.$t(
						'fb.blockDetails.checkBlock.conditionsTable.columns.fieldType'
					),
					field: 'field_type',
					sortable: false,
					width: '15%',
					default: this.$t('general.dash'),
				},
				{
					label: this.$t(
						'fb.blockDetails.checkBlock.conditionsTable.columns.operator'
					),
					field: 'operator',
					sortable: false,
					width: '15%',
					default: this.$t('general.dash'),
				},
				{
					label: this.$t(
						'fb.blockDetails.checkBlock.conditionsTable.columns.type'
					),
					field: 'type',
					sortable: false,
					width: '15%',
					default: this.$t('general.dash'),
				},
				{
					label: this.$t(
						'fb.blockDetails.checkBlock.conditionsTable.columns.value'
					),
					field: 'value',
					sortable: false,
					width: '30%',
					default: this.$t('general.dash'),
				},
				{
					label: '',
					field: 'edit',
					sortable: false,
					width: '0%',
					default: '',
					component: 'VButton',
					headerClass: 'has-padding-right-0',
					cellClass: 'is-button-tool has-padding-right-0',
					args: {
						href: '',
						title: this.editMode
							? this.$t('general.edit')
							: this.$t('general.inspect'),
						isTool: true,
						class: '',
						icon: this.editMode ? 'edit' : 'eye',
					},
					click: this.inspectConditionClicked,
				},
				{
					label: '',
					field: 'delete',
					sortable: false,
					width: '0%',
					default: '',
					component: 'VButton',
					headerClass: 'has-padding-right-0',
					cellClass: 'is-button-tool has-padding-right-0',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
					},
					click: this.deleteConditionClicked,
				},
			];
		},
		conditionTables() {
			const result = [];

			this.config.rules.forEach((group, groupKey) => {
				const groupRows = [];

				group.rules.forEach((rule, index) => {
					groupRows.push({
						group: groupKey,
						field: Field.getNameAsPath(rule.name),
						field_type: Field.translateVarType(rule.type),
						operator: getOperatorName(rule.operator),
						type: rule.rule_type
							? this.$t(
									'fb.conditions.' + rule.rule_type.toLowerCase()
							  )
							: false,
						value: rule.value.join(', '),
						edit: this.editMode,
						delete: this.editMode,
						key: index,
					});
				});

				result.push(groupRows);
			});

			return result;
		},
	},
	methods: {
		markBlockAsUpdated: function () {
			// no need to trigger this mutation more than once
			if (this.blockUpdated) return;

			// mark block as modified whenever a change to
			// the fields is made
			this.$store.commit(
				getStoreMutation('BLOCK_CONFIG_MODIFIED', 'BLOCKS')
			);

			this.blockUpdated = true;
		},
		conditionsUpdated: function () {
			this.markBlockAsUpdated();
		},
		addConditionGroup() {
			if (
				!this.config.rules ||
				typeof this.config.rules !== 'object'
			) {
				this.config.rules = [];
			}

			this.config.rules.push({
				rules: [],
			});

			this.conditionsUpdated();
		},
		deleteConditionGroup(key) {
			const group = this.config.rules[key] || false;

			if (!group) return;

			const deleteGroup = () => {
				this.config.rules.splice(key, 1);
				this.conditionsUpdated();
			};

			if (group.rules && group.rules.length > 0) {
				// ask confirmation to delete group
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t(
						'fb.blockDetails.checkBlock.conditionGroupDelete.confirm.title'
					),
					body: this.$t(
						'fb.blockDetails.checkBlock.conditionGroupDelete.confirm.body'
					),
					confirmButtonText: this.$t(
						'fb.blockDetails.checkBlock.conditionGroupDelete.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// do delete after confirmation
						deleteGroup();
					},
				});
			} else {
				deleteGroup();
			}
		},
		inspectConditionClicked({ group, key }) {
			const condition = this.getCondition(group, key);

			if (condition) {
				this.openCheckConditionDrawer(condition);
			}
		},
		addConditionClicked(group) {
			const conditionGroup = this.config.rules[group] || false;

			if (!conditionGroup || !conditionGroup.rules) return;

			this.openCheckConditionDrawer({}, conditionGroup);
		},
		getCondition(group, key) {
			const conditionGroup = this.config.rules[group] || false;

			if (!conditionGroup || !conditionGroup.rules) return false;

			return conditionGroup.rules[key] || false;
		},
		openCheckConditionDrawer(condition = {}, conditionGroup = false) {
			// open drawer to add/edit condition
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'checkCondition',
				data: {
					fields: this.blockInput || [],
					condition,
					callback: (updatedCondition) => {
						Object.assign(condition, updatedCondition);

						// add as new condition to given group
						if (conditionGroup) {
							conditionGroup.rules.push(condition);
						}

						this.conditionsUpdated();
					},
				},
			});
		},
		deleteConditionClicked({ group, key }) {
			const conditionGroup = this.config.rules[group] || false;

			const condition = this.getCondition(group, key);
			if (!condition) return;

			// ask confirmation to delete condition
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t(
					'fb.blockDetails.checkBlock.conditionDelete.confirm.title'
				),
				body: this.$t(
					'fb.blockDetails.checkBlock.conditionDelete.confirm.body'
				),
				confirmButtonText: this.$t(
					'fb.blockDetails.checkBlock.conditionDelete.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// do delete after confirmation
					conditionGroup.rules.splice(key, 1);
					this.conditionsUpdated();
				},
			});
		},
	},
};
</script>
