<template>
	<VOverlay
		:hasBackground="true"
		:hasJsonButton="false"
		:hasCloseButton="true"
		:closeOnBackgroundClick="true"
		:isLarge="true"
		:isVCentered="false"
		:hasFullwidthBody="true"
		:isFullHeight="isFullHeight"
		:title="title || null"
		:icon="icon"
		:modelValue="true"
		:hasScrollableContent="hasScrollableContent"
		@update:modelValue="overlayClosed"
		:useFocusTrap="drawers.length === 0 && !confirmationActive"
		:modalHasSharpCorner="true"
		@keydown.meta.s.prevent.stop="saveConfig"
	>
		<template #header>
			<template v-if="showAddBlock || showPredefinedList">
				<AddNewField v-if="showAddBlock" :blockType="type" />
				<VLink
					v-if="showPredefinedList"
					:text="$t('fb.blockDetails.startBlock.choosePredefined')"
					icon="list"
					:hasUnderline="true"
					@click.prevent.stop="openPredefinedList"
				/>
			</template>
			<template v-if="hasError">
				<div class="error-list-block-container">
					<VTitle
						:size="4"
						classes="error-list-title"
						@click="errorsListCollapsed = !errorsListCollapsed"
					>
						<span>
							{{$t('fb.blockDetails.title.errorList', {count: `(${blockErrors.length}) `})}}
						</span>
						<VIcon :name=" errorsListCollapsed? 'chevron-down': 'chevron-up'" />
					</VTitle>
					<ErrorsList
						:errorsListCollapsed="errorsListCollapsed"
						:errors="blockErrors"
					/>
				</div>
			</template>
		</template>

		<Component
			v-if="blockConfigComponent"
			:is="blockConfigComponent"
			v-model:description="description"
			v-model:preprocess="preprocessFunction"
		/>

		<template #footer>
			<template v-if="description">
				{{ description }}
			</template>
			<VButton
				v-if="editMode"
				:disabled="!modified"
				class="button-modal-footer"
				:text="$t('fb.blockDetails.saveButton')"
				@clicked.prevent.stop="saveConfig"
			/>
		</template>
	</VOverlay>
</template>

<script>
import { mapGetters } from 'vuex';
import {
	getStoreGetter,
	getStoreMutation,
	getStoreAction,
} from '@assets/scripts/store/config';
import { getBlockType } from '@modules/FlowBuilder/components/block';

import AddNewField from '@modules/FlowBuilder/materials/components/AddNewField.vue';

// import all possible Block Config components
import AddBlockConfig from '@modules/FlowBuilder/materials/blocks/AddBlockConfig.vue';
import CheckBlockConfig from '@modules/FlowBuilder/materials/blocks/CheckBlockConfig.vue';
import ConfigBlockConfig from '@modules/FlowBuilder/materials/blocks/ConfigBlockConfig.vue';
import ErrorBlockConfig from '@modules/FlowBuilder/materials/blocks/ErrorBlockConfig.vue';
import ReadBlockConfig from '@modules/FlowBuilder/materials/blocks/ReadBlockConfig.vue';
import ResultBlockConfig from '@modules/FlowBuilder/materials/blocks/ResultBlockConfig.vue';
import StartBlockConfig from '@modules/FlowBuilder/materials/blocks/StartBlockConfig.vue';
import WriteBlockConfig from '@modules/FlowBuilder/materials/blocks/WriteBlockConfig.vue';
import ExternalConnectorBlockConfig from '@modules/FlowBuilder/materials/blocks/ExternalConnectorBlockConfig.vue';

export default {
	name: 'TheBlockDetails',
	components: {
		AddBlockConfig,
		AddNewField,
		CheckBlockConfig,
		ConfigBlockConfig,
		ErrorBlockConfig,
		ReadBlockConfig,
		ResultBlockConfig,
		StartBlockConfig,
		WriteBlockConfig,
		ExternalConnectorBlockConfig,
	},
	data: function () {
		return {
			block: {},
			type: false,
			title: false,
			icon: false,
			blockConfigComponent: false,
			description: false,
			preprocessFunction: () => {},
			errorsListCollapsed: false,
		};
	},
	mounted: function () {
		// Get the currently active block
		this.block =
			this.$store.getters[getStoreGetter('CURRENT_BLOCK', 'BLOCKS')];

		if (!this.block) return;

		// determine type of block
		const type = getBlockType(this.block);
		this.type = type ? type.toLowerCase() : false;

		// set data based on block type
		switch (this.type) {
			case 'start':
				this.title = this.$t('fb.blockDetails.title.start');
				this.icon = 'diamond';
				this.blockConfigComponent = 'StartBlockConfig';
				break;
			case 'add':
				this.title = this.$t('fb.blockDetails.title.add');
				this.icon = 'plus';
				this.blockConfigComponent = 'AddBlockConfig';
				break;
			case 'check':
				this.title = this.$t('fb.blockDetails.title.check');
				this.icon =  'check';
				this.blockConfigComponent = 'CheckBlockConfig';
				break;
			case 'external':
				this.title = this.$t('fb.blockDetails.title.external');
				this.icon = 'result';
				this.blockConfigComponent = 'ExternalConnectorBlockConfig';
				break;
			case 'result':
				this.title = this.$t('fb.blockDetails.title.result');
				this.icon = 'end';
				this.blockConfigComponent = 'ResultBlockConfig';
				break;
			case 'read':
				this.title = this.$t('fb.blockDetails.title.read');
				this.icon = 'import';
				this.blockConfigComponent = 'ReadBlockConfig';
				break;
			case 'write':
				this.title = this.$t('fb.blockDetails.title.write');
				this.icon = 'export';
				this.blockConfigComponent = 'WriteBlockConfig';
				break;
			case 'error':
				this.title = this.$t('fb.blockDetails.title.error');
				this.icon = 'warning';
				this.blockConfigComponent = 'ErrorBlockConfig';
				break;
			case 'config':
				this.title = this.$t('fb.blockDetails.title.config');
				this.icon = 'gear';
				this.blockConfigComponent = 'ConfigBlockConfig';
				break;
		}
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate if flow is in Edit mode
			 */
			editMode: getStoreGetter('EDIT_MODE', 'FLOW'),
			/**
			 * Boolean to indicate whether user modified the block
			 * since the overlay was opened
			 */
			modified: getStoreGetter('MODIFIED', 'BLOCKS'),
			/**
			 * Get validation mode from the store
			 */
			validationMode: getStoreGetter('VALIDATION_MODE'),
			/**
			 * Get drawers to check if there is a drawers open
			 * if drawers are open no focus trap for block details
			 * if no drawers open focus trap will be used in block details 
			 */
			drawers: getStoreGetter('DRAWERS'),
			/**
			 * Boolean to indicate whether the confirmation
			 * popup should be displayed
			 */
			confirmationActive: getStoreGetter('CONFIRMATION_ACTIVE'),
		}),
		hasError: function () {
			return this.validationMode && this.blockErrors.length > 0;
		},
		blockErrors: function () {
			// Get all errors for current block
			return this.$store.getters[getStoreGetter('BLOCK_ERRORS', 'FLOW')](this.block.guid);
		},
		hasScrollableContent: function () {
			return ['start', 'add', 'write', 'result', 'external', 'read', 'check'].includes(this.type);
		},
		isFullHeight: function () {
			return this.type === 'config';
		},
		showAddBlock: function () {
			return (
				this.editMode && (this.type === 'start' || this.type === 'add')
			);
		},
		showPredefinedList: function () {
			return this.editMode && this.type === 'start';
		},
	},
	methods: {
		overlayClosed: function () {
			const closeOverlay = () => {
				this.$store.commit(
					getStoreMutation('CLOSE_BLOCK_DETAILS', 'BLOCKS')
				);
			};

			if (!this.modified) {
				// close immediately if config has not been modified
				closeOverlay();
			} else {
				// ask confirmation before closing if a change has been made
				// to the block config
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t('fb.blockDetails.close.confirm.title'),
					body: this.$t('fb.blockDetails.close.confirm.body'),
					confirmButtonText: this.$t(
						'fb.blockDetails.close.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeOverlay();
					},
				});
			}
		},
		saveConfig: function () {
			if (this.preprocessFunction) {
				this.preprocessFunction();
			}

			// dispatch action to save config of current block if no drawers are open
			// if statement is needed for keydown ctrl+s event
			if(this.drawers.length === 0) {
				this.$store.dispatch(
					getStoreAction('SAVE_CURRENT_BLOCK', 'BLOCKS')
				);
			}
		},
		openPredefinedList: function () {
			// open drawer with predefined fields
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'predefinedFields',
			});
		},
	},
};
</script>
