<template>
	<div class="page-with-options-wrapper" :class="{ 'is-invisible': !flowActive }">
		<TheAppOptions
			:backButtonText="$t('fb.flowOptions.back')"
			:backClickedFn="backClicked"
			:publishClickedFn="publishClicked"
			:validateClickedFn="validateClicked"
			:nameLabel="$t('fb.flowOptions.flowName')"
			:canBeSaved="canBeSaved"
			:canBePublished="canBePublished"
			:saveClickedFn="saveClicked"
			:editMode="editMode && nameIsEditable"
			:showButtons="editMode"
			:canBeValidated="canBeValidated"
			v-model:name="flowName"
			@update:name="updateName"
			:trimName="true"
			:trimFunction="trimFlowName"
		/>
		<div class="flow page-content js-page-content" ref="flow">
			<div
				class="flow-canvas"
				ref="canvas"
				:class="{
					'in-edit-mode': editMode,
				}"
			></div>
			<div class="flow-blocks">
				<FlowBlock
					v-for="(block, index) in blocks"
					:key="index"
					:guid="index"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Canvas from '@modules/FlowBuilder/components/canvas';
import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { ref } from 'vue';
import { mapGetters } from 'vuex';
import { trimFlowName } from '@modules/FlowBuilder/components/flow';
import {
	CHECK_IF_RESTFLOW_PUBLISHED,
	CHECK_FOR_EXISTING_REST_METHOD_FLOW,
} from '@modules/FlowBuilder/endpoints';

import TheAppOptions from '@materials/structures/TheAppOptions.vue';
import FlowBlock from '@modules/FlowBuilder/materials/components/FlowBlock';
import { log } from '@assets/scripts/components/notifications';

export default {
	name: 'TheCanvas',
	components: {
		TheAppOptions,
		FlowBlock,
	},
	data: function () {
		return {
			flowName: '',
			trimFlowName,
			nameIsEditable: false,
			unsubscribeAction: false,
			restMethodFlowExist: false,
		};
	},
	watch: {
		flowActive() {
			if (this.flowActive) {
				this.setFlowName();
			}
		},
	},
	setup() {
		const canvas = ref(null);
		const flow = ref(null);
		return { canvas, flow };
	},
	methods: {
		setFlowName: function () {
			this.flowName =
				this.currentFlow && this.currentFlow.name
					? this.currentFlow.name
					: '';
		},
		backClicked: function () {
			const leave = () => {
				// dispatch action to unset flow
				this.$store.dispatch(getStoreAction('UNSET_FLOW', 'FLOW'));
			};

			if (!this.canBeSaved) leave();
			else {
				// ask confirmation to leave canvas if an unsaved change
				// has been made to the flow
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t('fb.flowOptions.confirmLeave.title'),
					body: this.$t('fb.flowOptions.confirmLeave.body'),
					confirmButtonText: this.$t(
						'fb.flowOptions.confirmLeave.confirmButtonText'
					),
					confirmFn: () => {
						leave();
					},
				});
			}
		},
		publishClicked: function () {
			// ask confirmation before publishing
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('fb.flow.publish.confirm.title'),
				body: this.$t('fb.flow.publish.confirm.body'),
				confirmButtonText: this.$t(
					'fb.flow.publish.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// dispatch action to publish flow
					this.$store.dispatch(
						getStoreAction('PUBLISH_CURRENT_FLOW', 'FLOW')
					);
				},
			});
		},
		validateClicked: function () {
			// dispatch action to Validate flow
			this.$store.dispatch(
				getStoreAction('VALIDATE_CURRENT_FLOW', 'FLOW'),
				this.currentFlow
			);
		},
		checkIfRestMethodFlowExist: async function () {
			// check if flow already exist with the same name + method + api
			// by making an api call to check
			this.restMethodFlowExist = await useApiAsync(
				CHECK_FOR_EXISTING_REST_METHOD_FLOW,
				{
					parameters: {
						method: this.currentFlow.method,
						api_guid: this.currentFlow.api,
						flow_name: this.flowName,
						flow_guid: this.currentFlow.guid,
					},
				}
			);
			if (this.restMethodFlowExist) {
				// show error message
				log(
					this.$t('fb.flowErrors.restMethodFlowExist'),
					'danger'
				);
			}
		},
		saveClicked: async function () {
			await this.checkIfRestMethodFlowExist();
			// if flow already exist with the same name + method + api Display error/warning
			if (this.restMethodFlowExist) return;

			// dispatch action to save flow
			this.$store.dispatch(getStoreAction('SAVE_CURRENT_FLOW', 'FLOW'));
		},
		updateName: function (name) {
			// commit mutation in store
			this.$store.commit(getStoreMutation('SET_NAME', 'FLOW'), name);
		},
		checkIfFlowIsPublished: async function () {
			if (!this.currentFlow.guid) return;

			// check if current flow (not this version but the main flow) has a
			// published version
			const { is_published } = await useApiAsync(CHECK_IF_RESTFLOW_PUBLISHED, {
				keys: {
					guid: this.currentFlow.guid
				}
			});

			// if flow has a published version, the name is no longer editable
			this.nameIsEditable = !is_published;
		}
	},
	mounted: function () {
		Canvas.init(this.canvas, this.flow);
		
		// wait until DOM is fully loaded before painting flow
		// on the canvas. Wait is needed to be able to correctly
		// determine the dimensions of the canvas.
		window.addEventListener('DOMContentLoaded', () => {
			Canvas.initFlow();
		});
		this.setFlowName();

		this.checkIfFlowIsPublished();

		// subscribe to store action
		this.unsubscribeAction = this.$store.subscribeAction({
			after: async ({ type }) => {
				// check action type
				if (type === getStoreAction('LOAD_AND_SHOW_FLOW', 'FLOW')) {
					// check again if flow is published
					this.checkIfFlowIsPublished();
				}
			}
		});
	},
	unmounted: function () {
		Canvas.unmount();
		if (this.unsubscribeAction) this.unsubscribeAction();
	},
	computed: {
		...mapGetters({
			blocks: getStoreGetter('BLOCKS_BY_GUID', 'BLOCKS'),
			editMode: getStoreGetter('EDIT_MODE', 'FLOW'),
			currentFlow: getStoreGetter('CURRENT_FLOW', 'FLOW'),
			/**
			 * Boolean to indicate whether the canvas
			 * should be displayed
			 */
			flowActive: getStoreGetter('FLOW_ACTIVE', 'FLOW'),
			/**
			 * Boolean to indicate whether current flow can
			 * be saved
			 */
			canBeSaved: getStoreGetter('CAN_BE_SAVED', 'FLOW'),
			/**
			 * Boolean to indicate whether current flow can
			 * be published
			 */
			canBePublished: getStoreGetter('CAN_BE_PUBLISHED', 'FLOW'),
			/**
			 * Boolean to indicate whether current flow can
			 * be validate
			 */
			canBeValidated: getStoreGetter('CAN_BE_VALIDATED', 'FLOW'),
		}),
	},
};
</script>
