<template>
	<div class="spaced-content">
		<VField
			:label="$t('fb.blockDetails.errorBlock.setErrorCode')"
			:isFloating="true"
			:class="{ 'is-active': config.error_code }"
			v-if="editMode"
		>
			<VInput
				v-model="config.error_code"
				:placeholder="$t('fb.blockDetails.errorBlock.setErrorCode')"
				type="text"
				@update:modelValue="markModified"
			/>
		</VField>
		<template v-else>
			<VTitle
				:size="4"
				:text="$t('fb.blockDetails.errorBlock.errorCode')"
				class="has-margin-bottom-075"
				/>
			<p> {{ config.error_code || '-' }} </p>
		</template>

		<VField
			:label="$t('fb.blockDetails.errorBlock.setErrorMessage')"
			:isFloating="true"
			:class="{ 'is-active': config.error_message }"
			v-if="editMode"
		>
			<VInput
				v-model="config.error_message"
				:placeholder="$t('fb.blockDetails.errorBlock.setErrorMessage')"
				type="text"
				@update:modelValue="markModified"
			/>
		</VField>
		<template v-else>
			<VTitle
				:size="4"
				:text="$t('fb.blockDetails.errorBlock.errorMessage')"
				class="has-margin-bottom-075 has-margin-top-4"
				/>
			<p> {{ config.error_message || '-' }} </p>
		</template>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getBlockConfig } from '@modules/FlowBuilder/components/block';
import { getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';

export default {
	name: 'ErrorBlockConfig',
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate if flow is in Edit mode
			 */
			editMode: getStoreGetter('EDIT_MODE', 'FLOW'),
			/**
			 * Get block that is currently viewed/edited
			 */
			block: getStoreGetter('CURRENT_BLOCK', 'BLOCKS'),
		}),
		/**
		 * Get desctructured config of block
		 */
		config: function () {
			return getBlockConfig(this.block);
		},
	},
	methods: {
		markModified: function () {
			// mark block as modified whenever a change to
			// the fields is made
			this.$store.commit(
				getStoreMutation('BLOCK_CONFIG_MODIFIED', 'BLOCKS')
			);
		},
	},
};
</script>
