<template>
	<div
		:class="[
			'flow-block',
			{ [`flow-block-${type}`]: type },
			{ 'is-hidden': !block },
			{ 'in-edit-mode': editMode },
			{ 'in-test-mode': testMode },
			{ 'has-error': hasError },
		]"
		:id="'block-' + guid"
		:style="{
			top: y + 'px',
			left: x + 'px',
			width: width + 'px',
			height: height + 'px',
		}"
		@click="clicked"
	>
		<div class="flow-block-top">
			<VIcon v-if="icon" :name="icon" />

			<div v-if="title" class="flow-block-title">
				{{ title }}
			</div>
		</div>

		<VButton
			v-if="canBeDeleted"
			size="is-medium"
			icon="delete"
			:title="$t('fb.blocks.delete')"
			:is-tool="true"
			classes="flow-block-icon flow-block-delete"
			@clicked.prevent.stop="deleteClicked"
		/>

		<VFlag v-if="testMode" classes="flow-block-icon" />

		<div v-if="description" class="flow-block-bottom">
			{{ description }}
		</div>

		<div
			v-if="isSplittingBlock"
			class="flow-block-port flow-block-port-false"
		>
			{{ $t('general.false') }}
		</div>

		<div
			v-if="isSplittingBlock"
			class="flow-block-port flow-block-port-true"
		>
			{{ $t('general.true') }}
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
	getStoreGetter,
	getStoreAction,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { dimensions } from '@modules/FlowBuilder/components/canvas';
import {
	getBlockPosition,
	getBlockType,
	blockCanBeDeletedByUser,
	isSplittingBlock,
} from '@modules/FlowBuilder/components/block';
import { isEmpty } from 'lodash';
import Helpers from '@assets/scripts/helpers';

export default {
	name: 'FlowBlock',
	props: {
		/**
		 * GUID of the Block to display
		 */
		guid: {
			type: String,
		},
	},
	data: function () {
		return {
			/**
			 * Block object to display
			 */
			block: false,
			/**
			 * String, holds the type of the Block, in lowercase
			 */
			type: false,
			/**
			 * Boolean indicator whether the Block is a
			 * splitting block, i.e. a Check Block
			 */
			isSplittingBlock: false,
			/**
			 * Integer, additional X offset to place the block.
			 * Used for Start and Close block types, because they
			 * are smaller than other block types, but their center
			 * should be at the same point as wider blocks in the
			 * same column
			 */
			xOffset: 0,
			/**
			 * Integer, width of the Block. Imported from flow.js
			 * to use single source of truth, since blocks in JointJS
			 * will have the same dimensions as their HTML counterparts
			 * that are created with this Vue Component
			 */
			width: dimensions.blockWidth,
			/**
			 * Integer, height of the Block. See 'width' for more info.
			 */
			height: dimensions.blockHeight,
		};
	},
	methods: {
		/**
		 * Called after click on Block, outside of the
		 * 'Delete' button
		 */
		clicked: function () {
			// do not show details for Close block
			if (this.type === 'close') return;

			// commit mutation to select block and
			// show its details
			this.$store.commit(
				getStoreMutation('SELECT_BLOCK', 'BLOCKS'),
				this.block
			);
		},
		/**
		 * Called after click on the 'Delete' button
		 */
		deleteClicked: function () {
			// check if block can be deleted
			if (this.canBeDeleted) {
				// ask confirmation to delete block
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t('fb.blocks.deleteConfirm.title'),
					body: this.$t('fb.blocks.deleteConfirm.body'),
					confirmButtonText: this.$t(
						'fb.blocks.deleteConfirm.confirmButtonText'
					),
					confirmFn: () => {
						// dispatch action to delete block
						this.$store.dispatch(
							getStoreAction('BLOCK_DELETED', 'BLOCKS'),
							this.block
						);
					},
				});
			}
		},
	},
	mounted: function () {
		// Use GUID from props to get Block
		const block = this.$store.getters[
			getStoreGetter('BLOCK_BY_GUID', 'BLOCKS')
		](this.guid);

		// check if block was found
		if (block && !isEmpty(block)) {
			this.block = block;

			// get some info about the block only once, since
			// it will never change again after initialisation
			this.type = getBlockType(this.block);
			if (this.type) this.type = this.type.toLowerCase();

			this.isSplittingBlock = isSplittingBlock(this.block);

			if (this.type === 'start') {
				this.width = dimensions.startBlockWidth;
				this.height = dimensions.startBlockHeight;
				this.xOffset =
					(dimensions.blockWidth - dimensions.startBlockWidth) / 2;
			} else if (this.type === 'close') {
				this.width = dimensions.closeBlockWidth;
				this.height = dimensions.closeBlockHeight;
				this.xOffset =
					(dimensions.blockWidth - dimensions.closeBlockWidth) / 2;
			}
		}
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate if flow is in Edit mode
			 */
			editMode: getStoreGetter('EDIT_MODE', 'FLOW'),
			/**
			 * Boolean to indicate if flow is in Test mode
			 */
			testMode: getStoreGetter('TEST_MODE', 'FLOW'),
			/**
			 * xFlowBlockStart to use as X offset for positioning
			 * block
			 */
			xFlowBlockStart: getStoreGetter('FLOW_BLOCK_START', 'BLOCKS'),
			/**
			 * Get all errors for current flow
			 */
			errors: getStoreGetter('VALIDATION_ERRORS'),
			/**
			 * Get validation mode
			 */
			validationMode: getStoreGetter('VALIDATION_MODE'),
			isScriptFlow: getStoreGetter('IS_SCRIPT_FLOW', 'FLOW'),
		}),
		hasError: function () {
			return this.validationMode && this.blockErrors.length > 0;
		},
		blockErrors: function () {
			// Get all errors for current block
			return this.$store.getters[getStoreGetter('BLOCK_ERRORS', 'FLOW')](this.guid);
		},
		icon: function () {
			let icon = false;

			switch (this.type) {
				case 'start':
					icon = 'diamond';
					break;
				case 'add':
					icon = 'plus';
					break;
				case 'check':
					icon = 'check';
					break;
				case 'external':
					icon = 'result';
					break;
				case 'result':
					icon = 'end';
					break;
				case 'read':
					icon = 'import';
					break;
				case 'write':
					icon = 'export';
					break;
				case 'error':
					icon = 'warning';
					break;
				case 'config':
					icon = 'gear';
					break;
			}

			return icon;
		},
		description: function () {
			if (!this.type || ['close', 'config'].includes(this.type)) return false;
			else if (this.type === 'start') {
				return this.$t(
					'fb.blocks.description.fieldsSpecified',
					this.outputCount
				);
			} else if (this.type === 'read' || this.type === 'write') {
				return this.$t('fb.blocks.description.method', {
					method: this.method || '-',
				});
			} else if (this.type === 'check') {
				return this.$t(
					'fb.blocks.description.conditionalsSet',
					this.ruleCount
				);
			} else if (this.type === 'add') {
				return this.$t(
					'fb.blocks.description.fieldsAdded',
					this.appendCount
				);
			} else if (this.type === 'result') {
				if (Helpers.obj.getProp('config|full_output', this.block, false)) {
					return this.$t(
						'fb.blocks.description.fullOutput');
				}
				return this.$t(
					'fb.blocks.description.fieldsSelected',
					this.resultCount
				);
			} else if (this.type === 'error') {
				return this.$t('fb.blocks.description.errorCode', {
					code: this.block.config.error_code || '-',
				});
			} else if (this.type === 'external') {
				return this.$t('fb.blocks.description.external', {
					selection: this.block.config.conn_name && this.block.config.method_name ? `${this.block.config.conn_name} - ${this.block.config.method_name}` :  this.block.config.conn_name || '-',
				});
			} else {
				return false;
			}
		},
		/**
		 * Use store getter to get output variables
		 * count for this block
		 */
		outputCount: function () {
			return this.$store.getters[
				getStoreGetter('BLOCK_OUTPUT_COUNT', 'BLOCKS')
			](this.guid);
		},
		/**
		 * Use store getter to get READ/WRITE method
		 * for this block
		 */
		method: function () {
			return this.$store.getters[
				getStoreGetter('BLOCK_METHOD', 'BLOCKS')
			](this.guid);
		},
		/**
		 * Use store getter to get a count of the
		 * configured conditions for a check block
		 */
		ruleCount: function () {
			return this.$store.getters[
				getStoreGetter('BLOCK_RULE_COUNT', 'BLOCKS')
			](this.guid);
		},
		/**
		 * Use store getter to get a count of the
		 * configured variables to append in an
		 * add/append block
		 */
		appendCount: function () {
			return this.$store.getters[
				getStoreGetter('BLOCK_APPEND_COUNT', 'BLOCKS')
			](this.guid);
		},
		/**
		 * Use store getter to get a count of the
		 * configured outputted variables for a
		 * result block
		 */
		resultCount: function () {
			return this.$store.getters[
				getStoreGetter('BLOCK_RESULT_COUNT', 'BLOCKS')
			](this.guid);
		},
		/**
		 * Boolean to indicate if block can be deleted by the user
		 */
		canBeDeleted: function () {
			return (
				this.block &&
				this.editMode &&
				blockCanBeDeletedByUser(this.block) &&
				!this.isScriptFlow
			);
		},
		/**
		 * String, holds the title to display in the Block
		 * header. Value is based on Block type.
		 */
		title: function () {
			return this.type ? this.$t('fb.blocks.title.' + this.type) : false;
		},
		/**
		 * Integer, determines 'left' CSS property to correctly
		 * position the block over the corresponding block on
		 * the JointJS canvas
		 */
		x: function () {
			if (!this.block) return 0;

			// any change in this calculation should also be done
			// in flow.js to make sure positioning of blocks still match
			return (
				this.xOffset +
				this.xFlowBlockStart +
				getBlockPosition(this.block, 'x') * dimensions.xStep
			);
		},
		/**
		 * Integer, determines 'top' CSS property to correctly
		 * position the block over the corresponding block on
		 * the JointJS canvas
		 */
		y: function () {
			if (!this.block) return 0;

			// any change in this calculation should also be done
			// in flow.js to make sure positioning of blocks still match
			return (
				dimensions.yStart +
				getBlockPosition(this.block, 'y') * dimensions.yStep
			);
		},
	},
};
</script>
