<template>
	<div
		class="content-header has-padding has-bottom-divider is-flex is-flex-grow-0 is-align-items-flex-end"
	>
		<VField
			:label="$t('fb.blockDetails.externalConnectorBlock.chooseSystem')"
			:isFloating="true"
			:class="{ 'is-active': config.conn_guid }"
			class="has-padding-right-1"
		>
			<VSelect
				:options="systemOptions"
				v-model="config.conn_guid"
				@change="() => systemUpdated()"
				:disabled="!editMode"
			/>
		</VField>
		<VField
			:label="$t('fb.blockDetails.externalConnectorBlock.chooseConnection')"
			:isFloating="true"
			:class="{ 'is-active': config.method_guid }"
		>
			<VSelect
				:options="externalConnectorOptions"
				v-model="config.method_guid"
				@change="() => externalConnectorUpdated()"
				:disabled="!editMode || !config.conn_guid"
			/>
		</VField>

		<VSearch v-model="search" />
	</div>
	<SortableDataTable
		:hoverable="false"
		defaultSort="to"
		:data="filteredData"
		:columns="columns"
		:emptyText="$t('fb.blockDetails.externalConnectorBlock.table.noResults')"
		tableClasses="scrollable-content"
	>
		<template
			v-for="(row, key) in tableFields"
			:key="key"
			#[`from-${key}`]
		>
			<template v-if="editMode">
				<VField
					:label="$t('fb.blockDetails.externalConnectorBlock.field')"
					:isFloating="true"
					:class="{
						'is-active': config.mapping[key]['from'],
					}"
					v-if="config.mapping[key]"
				>
					<VSelect
						:options="fieldOptions(row.type, fields)"
						v-model="config.mapping[key]['from']"
						@change="mappingUpdated"
					/>
				</VField>
			</template>
			<template v-else>
				{{
					getNameAsPath(config.mapping[key]['from']) ||
					$t('general.dash')
				}}
			</template>
		</template>
	</SortableDataTable>
</template>

<script>
import { mapGetters } from 'vuex';
import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import { getBlockGuid, getBlockConfig } from '@modules/FlowBuilder/components/block';
import {
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import {
	GET_EXTERNAL_CONNECTORS,
	GET_EXTERNAL_CONNECTOR_BY_ID,
} from '@modules/FlowBuilder/endpoints';

export default {
	name: 'ExternalConnectorBlockConfig',
	props: {
		description: {
			type: null,
		},
		preprocess: {
			type: null,
		},
	},
	data: function () {
		return {
			search: '',
			availableExternalConnectors: [],
			currentExternalConnector: false,
			mappingCache: {},
			fieldOptions: Field.createFieldOptions,
			columns: [
				{
					label: this.$t('fb.functions.mapping.columns.systemField'),
					field: 'to',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('fb.functions.mapping.columns.flowField'),
					field: 'from',
					sortable: false,
					searchable: false,
					default: this.$t('general.dash'),
					component: 'VSelect',
				},
				{
					label: this.$t('fb.functions.mapping.columns.type'),
					field: 'field_type',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('fb.functions.mapping.columns.required'),
					field: 'required',
					sortable: true,
					searchable: false,
					default: this.$t('general.dash'),
				},
			],
		};
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate if flow is in Edit mode
			 */
			editMode: getStoreGetter('EDIT_MODE', 'FLOW'),
			/**
			 * Get block that is currently viewed/edited
			 */
			block: getStoreGetter('CURRENT_BLOCK', 'BLOCKS'),
		}),
		/**
		 * Get desctructured config of block
		 */
		config: function () {
			return getBlockConfig(this.block);
		},
		/**
		 * Determine current available input fields for the block
		 * These fields come from the Flow State
		 */
		fields: function () {
			const guid = getBlockGuid(this.block);

			// return empty array if no block guid found
			if (!guid) return [];

			// get calculated input for block
			return this.$store.getters[getStoreGetter('BLOCK_INPUT', 'BLOCKS')](
				guid
			);
		},
		tableFields: function () {
			const rows = [];

			if (!this.currentExternalConnector) return rows;

			// loop over input fields for external connector
			this.currentExternalConnector.input.forEach((row, index) => {
				rows.push({
					to: Field.getNameAsPath(row.name),
					from: this.config.mapping[index] && this.config.mapping[index]['from'] ?
						this.fieldNameViewOnly(this.config.mapping[index]['from']) :
						this.$t('general.dash'),
					required: row.validation.required
						? this.$t('general.yes')
						: this.$t('general.no'),
					type: row.type,
					field_type: Field.translateVarType(row.type),
					key: index,
				});
			});

			return rows;
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// return all if no search entered
			if (this.search.length === 0) return this.tableFields;

			// filter on search string
			return Helpers.filterByString(
				this.tableFields,
				this.search,
				this.searchableCols
			);
		},
		externalConnectorOptions: function () {
			if (!this.availableExternalConnectors || this.availableExternalConnectors.length < 1)
				return [];

			const options = [];

			this.availableExternalConnectors.forEach((row) => {
				// push just the connectors that matches the system filter
				if (this.config.conn_guid === row.conn_guid) {
					options.push({
						guid: row.guid,
						name: row.name,
					});
				}
			});

			// map available external connectors into list for VSelect
			return Helpers.mapObjectArrayForSelect(options, {
				key: 'guid',
				text: 'name',
			});
		},
		systemOptions: function () {
			if (!this.availableExternalConnectors || this.availableExternalConnectors.length < 1)
				return [];

			const options = [];

			this.availableExternalConnectors.forEach((row) => {
				options.push({
					value: row.conn_guid,
					text: row.conn_name,
				});
			});
			// filter dublicated values
			return options.filter(
				(value, index, self) =>
					index === self.findIndex((t) => t.text === value.text)
			);
		},
	},
	mounted: async function () {
		// get available external connectors
		const externalConnectors = await useApiAsync(GET_EXTERNAL_CONNECTORS);

		if (externalConnectors) {
			this.availableExternalConnectors = externalConnectors;
		}

		this.setMappingCache();

		this.systemUpdated(true);
		this.externalConnectorUpdated(true);
	},
	methods: {
		getNameAsPath: function (input) {
			return Field.getNameAsPath(input);
		},
		markModified: function () {
			// mark block as modified whenever a change to
			// the fields is made
			this.$store.commit(
				getStoreMutation('BLOCK_CONFIG_MODIFIED', 'BLOCKS')
			);
		},
		externalConnectorUpdated: async function (initial = false) {
			// load chosen external connector meta
			await this.getExternalConnector();

			// set mapping for new external connector
			this.setMapping();

			// get some data from chosen external connector to copy to block config
			if (this.currentExternalConnector) {
				const { guid, name, conn_guid, conn_name } = this.currentExternalConnector || {};
				Object.assign(this.config, { method_guid: guid, method_name: name, conn_guid, conn_name });
			}

			if (!initial) this.markModified();
		},
		systemUpdated: async function (initial = false) {
			// clear method_guid when change the system selection
			if (!initial) {
				this.config.method_guid = '';
			}

			// load chosen external connector meta if it exist
			await this.getExternalConnector();

			// get some data from chosen system to copy to block config
			this.systemOptions.forEach((option) => {
				if (option.value === this.config.conn_guid) {
					Object.assign(this.config, { conn_name: option.text });
				}
			});

			if (!initial) this.markModified();
		},
		getExternalConnector: async function () {
			if (!this.config.method_guid) {
				// unset current external connector and return
				this.currentExternalConnector = false;
				this.config.method_name = '';
				this.config.method_guid = '';
				return;
			}

			// load current external connector from nominow api
			this.currentExternalConnector = await useApiAsync(GET_EXTERNAL_CONNECTOR_BY_ID, {
				keys: {
					guid: this.config.method_guid
				}
			});
		},
		setMapping: function () {
			this.config.mapping = [];

			if (
				this.currentExternalConnector &&
				this.currentExternalConnector.input &&
				this.currentExternalConnector.input.length > 0
			) {
				this.currentExternalConnector.input.forEach((row) => {
					this.config.mapping.push(
						this.getFromMappingCache(row.name)
					);
				});
			}
		},
		fieldNameViewOnly: function (name) {
			if (!name) return this.$t('general.dash');
			return Field.getNameAsPath(name);
		},
		setMappingCache: function () {
			if (!this.config.method_guid) return;

			if (!this.mappingCache[this.config.method_guid]) {
				this.mappingCache[this.config.method_guid] = [];
			}

			// update cache, used when switching between Connections
			this.mappingCache[this.config.method_guid] = Helpers.cloneObject(
				this.config.mapping
			);
		},
		mappingUpdated: function () {
			this.setMappingCache();
			this.markModified();
		},
		getFromMappingCache: function (name) {
			const result = {
				to: name,
				from: '',
			};

			if (!this.config.method_guid || !this.mappingCache[this.config.method_guid])
				return result;

			if (this.mappingCache[this.config.method_guid]) {
				const cached = this.mappingCache[this.config.method_guid].filter(
					(row) => row.to === name
				);

				if (cached.length > 0) {
					result.from = cached[0].from || '';
				}
			}

			return result;
		},
	},
};
</script>
