<template>
	<div class="content-header has-padding is-align-right has-bottom-divider">
		<VSearch v-model="search" />
	</div>
	<SortableDataTable
		tableClasses="scrollable-content"
		:rowClickedFn="inspectClicked"
		defaultSort="field_name"
		:data="filteredData"
		:columns="columns"
		:emptyText="$t('fb.blockDetails.startBlock.table.noResults')"
	>
		<template
				v-for="(field, key) in functionListFields"
				:key="key"
				#[`field_name-extra-${key}`]
			>
				{{ Field.getChildName(field.name) }}
				<VTooltip
					:text="$t('fb.blockDetails.startBlock.addedThroughFunctionList')"
				/>
			</template>
	</SortableDataTable>
</template>

<script>
import { mapGetters } from 'vuex';
import Helpers from '@assets/scripts/helpers';
import { getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';
import Field from '@assets/scripts/components/field';
import { formatForStartBlockTable } from '@assets/scripts/components/field';
import { getVariablesFromFunctionList } from '@assets/scripts/components/function-lists';

export default {
	name: 'StartBlockConfig',
	props: {
		description: {
			type: null,
		},
		preprocess: {
			type: null
		},
	},
	data: function () {
		return {
			search: '',
			functionListFields: [],
			Field
		};
	},
	mounted: function () {
		this.setDescription();
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate if flow is in Edit mode
			 */
			editMode: getStoreGetter('EDIT_MODE', 'FLOW'),
			/**
			 * Get start block fields of block that is currently viewed/edited
			 */
			fields: getStoreGetter('START_BLOCK_FIELDS', 'BLOCKS'),
		}),
		columns: function () {
			// definition of table columns
			const columns = [
				{
					label: this.$t('field.name'),
					field: 'field_name',
					sortable: true,
					searchable: true,
					leveled: true,
					default: this.$t('general.dash'),
					customSort: this.sortByFieldName,
				},
				{
					label: this.$t('field.type'),
					field: 'field_type',
					width: '15%',
					sortable: false,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.default_value'),
					field: 'default_value',
					width: '20%',
					sortable: false,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.validation'),
					field: 'validation',
					width: '20%',
					sortable: false,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.required_field'),
					field: 'required_field',
					width: '15%',
					sortable: false,
					default: this.$t('general.dash'),
				},
				{
					label: '',
					field: 'edit',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.editMode
							? this.$t('general.edit')
							: this.$t('general.inspect'),
						isTool: true,
						class: '',
						icon: this.editMode ? 'edit' : 'eye',
					},
					click: this.inspectClicked,
				},
			];

			// add delete column if applicable
			if (this.editMode) {
				columns.push({
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
					},
					click: this.deleteClicked,
				});
			}

			return columns;
		},
		tableFields: function () {
			return formatForStartBlockTable(this.fields, this.functionListFields);
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// filter on search string
			const matches = Helpers.filterByString(
				this.tableFields,
				this.search,
				this.searchableCols
			);

			// also add ancestors of matches to matches
			if (matches.length < this.tableFields.length) {
				Field.addAncestorsOfMatches(
					matches,
					this.tableFields,
					'sort_name'
				);
			}

			return matches;
		},
		fieldCount: function () {
			return this.fields.length + this.functionListFields.length;
		},
	},
	methods: {
		inspectClicked(row) {
			if (row.disabled) return;

			// open drawer with field information
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'startFieldDetails',
				data: row.key,
			});
		},
		deleteClicked({ key }) {
			// ask confirmation to delete field
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('fb.blockDetails.startBlock.delete.confirm.title'),
				body: this.$t('fb.blockDetails.startBlock.delete.confirm.body'),
				confirmButtonText: this.$t(
					'fb.blockDetails.startBlock.delete.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// do delete after confirmation
					this.fields.splice(key, 1);
					this.blockUpdated();
				},
			});
		},
		blockUpdated: function () {
			// mark block as modified whenever a change to
			// the fields is made
			this.$store.commit(
				getStoreMutation('BLOCK_CONFIG_MODIFIED', 'BLOCKS')
			);
		},
		setDescription() {
			this.$emit(
				'update:description',
				this.$t('fb.blockDetails.description.start', {
					count: this.fieldCount,
				})
			);
		},
		sortByFieldName(a, b, isAsc) {
			return Field.orderParentChildList(a.sort_name, b.sort_name, isAsc);
		},
		async setFunctionListFields() {
			this.functionListFields = [];
			
			// for loop used because of the await inside
			for (const field of this.fields) {
				const functionListFields = await getVariablesFromFunctionList(field);

				if (functionListFields) {
					this.functionListFields = this.functionListFields.concat(functionListFields);
				}
			}
		}
	},
	watch: {
		fieldCount() {
			this.setDescription();
		},
		fields: {
			handler() {
				this.setFunctionListFields();
			},
			deep: true,
			immediate: true
		}
	},
};
</script>
