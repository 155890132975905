// Documentation: https://resources.jointjs.com/docs/jointjs/v3.4/joint.html
// Tutorials: https://resources.jointjs.com/tutorial/introduction
import * as joint from 'jointjs';
import { isEmpty } from 'lodash';
import i18n from '@assets/i18n';
import store from '@assets/scripts/store';
import { debug } from '@assets/scripts/components/notifications';
import {
	getStoreMutation,
	getStoreGetter,
	getStoreAction,
} from '@assets/scripts/store/config';
import {
	getBlockPosition,
	getBlockChildCol,
	isSplittingBlock,
	getBlockConnections,
	getBlockChildren,
	isCentralAxisBlock,
	getBlockGuid,
	getBlockType,
} from '@modules/FlowBuilder/components/block';
import Helpers from '@assets/scripts/helpers';

// translate function of vue-i18n
const { t } = i18n.global;

const TRUE_SIDE = 'right';
const FALSE_SIDE = 'left';

let graph, paper;
let unsubscribeFromStore = false;
let canvasBlocks = {};
let blocks = {};
let maxDepth = 0;
let $container = false;
let isScriptFlow = false;

// various colors used in the Canvas Flow
const colors = {
	white: '#FFFFFF',
	darkBlue: '#24126A',
	lightBlue: '#BADCFF',
	purple: '#A335F6',
};

// various settings used in the Canvas Flow, and in FlowBlock.vue
export const dimensions = {
	// dimensions for default Block
	blockWidth: 320,
	blockHeight: 130,
	// dimensions for Start Block
	startBlockWidth: 208,
	startBlockHeight: 112,
	// dimensions for Close Block
	closeBlockWidth: 144,
	closeBlockHeight: 56,
	// border radius of default block
	blockRadius: 5,
	// dimensions for TRUE/FALSE port on Check Block
	exitBlockWidth: 64,
	exitBlockHeight: 37,
	// border radius of TRUE/FALSE port
	exitBlockRadius: 33,
	// offset of TRUE/FALSE block to side of parent block
	exitBlockOffset: 48,
	// horizontal step for block when flow diverges, in pixels
	xStep: 192,
	// vertical step for block on next row, in pixels
	yStep: 400,
	// vertical offset for start of flow
	yStart: 0,
	// padding for canvas
	canvasPadding: {
		top: 0, // keep this same as yStart
		horizontal: 50,
		bottom: 50,
	},
	// horizontal position for start of flow
	// will be set based on width of canvas
	xStart: 0,
	// grid size of JointJS object
	gridSize: 1,
	// radius of corners of links between blocks
	linkRadius: 50,
	// padding determines how far from a block a link
	// can start making turns
	routerPadding: 40,
};

// template for all blocks to be placed on the canvas
const blockTemplate = new joint.shapes.standard.Rectangle({
	size: {
		width: dimensions.blockWidth,
		height: dimensions.blockHeight,
	},
	attrs: {
		body: {
			fill: colors.white,
			rx: dimensions.blockRadius,
			ry: dimensions.blockRadius,
			strokeWidth: 0,
		},
		label: {
			fill: colors.darkBlue,
		},
	},
});

// template for all TRUE/FALSE port blocks
const exitBlockTemplate = new joint.shapes.standard.Rectangle({
	size: {
		width: dimensions.exitBlockWidth,
		height: dimensions.exitBlockHeight,
	},
	attrs: {
		body: {
			fill: colors.lightBlue,
			strokeWidth: 0,
			rx: dimensions.exitBlockRadius,
			ry: dimensions.exitBlockRadius,
		},
		label: {
			fill: colors.darkBlue,
		},
	},
});

/**
 * Initialises the JointJS library
 * Called from TheCanvas.vue component on mount
 *
 * @param {DOM Element} $canvas
 *  Element that will serve as canvas for
 *  the JointJS library
 *
 * @param {DOM Element} $flow
 *  Wrapping element around the canvas that
 *  provides the scrolling if needed
 *
 * @returns {void}
 */
const init = ($canvas, $flow) => {
	// save flow in local variable
	$container = $flow;

	// create new Graph
	graph = new joint.dia.Graph();

	// create new Paper
	paper = new joint.dia.Paper({
		el: $canvas,
		model: graph,
		width: null,
		height: null,
		gridSize: dimensions.gridSize,
		interactive: false,
	});

	debug('Initted canvas', {
		$canvas,
		$flow,
		paper,
	});

	// subscribe to state updates of Store
	unsubscribeFromStore = store.subscribe((mutation) => {
		// only act when current flow is updated
		if (
			mutation.type !== getStoreMutation('UPDATE_BLOCKS', 'BLOCKS') &&
			mutation.type !== getStoreMutation('RESET', 'BLOCKS')
		)
			return;

		initFlow();
	});

	window.addEventListener(
		'resize',
		Helpers.debounce(() => {
			initFlow();
		}, 200)
	);

	initFlow();
};

/**
 * Called from TheCanvas component, when component is unmounted
 */
const unmount = () => {
	// unsubscribe from store mutations
	if (unsubscribeFromStore) unsubscribeFromStore();
};

const initFlow = () => {
	const setXStart = () => {
		// calculate starting X coordinate based on canvas width
		dimensions.xStart = getStartX();
	};

	// set xStart initially
	setXStart();

	// get blocks keyed by GUID
	blocks = store.getters[getStoreGetter('BLOCKS_BY_GUID', 'BLOCKS')];

	// check whether current flow is a script flow
	isScriptFlow = store.getters[getStoreGetter('IS_SCRIPT_FLOW', 'FLOW')];

	// calculate position on the canvas for
	// all blocks
	calculatePositionsForAllBlocks();

	// add complete flow to canvas
	fillCanvas();

	debug('Blocks placed', {
		blocks,
		$container,
		startX: getStartX(),
	});
};

/**
 * Function to create current flow on the canvas
 *
 * @returns {void}
 */
const fillCanvas = () => {
	canvasBlocks = {};
	graph.clear();

	if (isEmpty(blocks)) return;

	// start by getting the Start Block
	const startBlock = getStartBlock();

	if (startBlock) {
		// recursively place blocks to canvas,
		// starting with Start block
		placeBlockRecursive(startBlock);

		// create links between blocks only after
		// all blocks are placed on the canvas
		createLinks();

		// resize canvas to fit content, for options see:
		// https://resources.jointjs.com/docs/jointjs/v3.4/joint.html#dia.Paper.prototype.fitToContent
		paper.fitToContent({
			padding: dimensions.canvasPadding,
			allowNewOrigin: 'negative',
		});

		// set starting X coord for the FlowBlocks, to make sure they
		// are positioned equal to the Canvas Blocks
		store.commit(
			getStoreMutation('UPDATE_FLOW_BLOCK_START', 'BLOCKS'),
			getStartX() + paper.translate().tx
		);
	}
};

/**
 * Places a new block on the canvas and connects
 * it to its parent(s)
 *
 * @param {Object} block
 *  Block Object to place on the canvas
 *
 * @returns {void}
 */
const placeBlock = (block) => {
	if (!block) return;

	// clone template block
	const canvasBlock = blockTemplate.clone();
	let xOffset = 0;

	// resize cloned block and calculate additional
	// x offset for the smaller Start and Close blocks
	const blockType = getBlockType(block);
	if (blockType === 'START') {
		canvasBlock.resize(
			dimensions.startBlockWidth,
			dimensions.startBlockHeight
		);
		xOffset = (dimensions.blockWidth - dimensions.startBlockWidth) / 2;
	} else if (blockType === 'CLOSE') {
		canvasBlock.resize(
			dimensions.closeBlockWidth,
			dimensions.closeBlockHeight
		);
		xOffset = (dimensions.blockWidth - dimensions.closeBlockWidth) / 2;
	}

	// set position based on block offset, depth
	// and step sizes in both directions
	// any change in this calculation should also be done
	// in FlowBlock.vue to make sure positioning of blocks still match
	canvasBlock.position(
		xOffset +
			dimensions.xStart +
			getBlockPosition(block, 'x') * dimensions.xStep,
		dimensions.yStart + getBlockPosition(block, 'y') * dimensions.yStep
	);
	canvasBlock.attr('label/text', getBlockGuid(block).split('-')[0]);

	// place block on canvas
	canvasBlock.addTo(graph);

	// add ref to block in global object for later reference
	canvasBlocks[getBlockGuid(block)] = canvasBlock;

	if (isSplittingBlock(block)) {
		/**
		 * Helper function to create an Exit Block that
		 * is embedded in the current Block to function as
		 * exit for blocks that split the flow
		 *
		 * @param {String} side
		 *  Either 'left' or 'right'
		 *
		 * @returns {Object}
		 *  Created Exit Block
		 */
		const createExitBlock = (side = TRUE_SIDE) => {
			// clone exit block template
			const exitBlock = exitBlockTemplate.clone();

			// add correct label to exit block
			exitBlock.attr(
				'label/text',
				side === TRUE_SIDE ? t('general.true') : t('general.false')
			);

			// add exit block to canvas
			exitBlock.addTo(graph);

			// embed exit block in parent block
			canvasBlock.embed(exitBlock);

			// position block relative to parent block
			exitBlock.position(
				side === TRUE_SIDE
					? dimensions.blockWidth -
							dimensions.exitBlockOffset -
							dimensions.exitBlockWidth
					: dimensions.exitBlockOffset,
				dimensions.blockHeight - dimensions.exitBlockHeight / 2,
				{ parentRelative: true }
			);

			// return created block
			return exitBlock;
		};

		// create multiple 'exits' for a block
		// that splits the flow
		canvasBlock.exits = {
			outTrue: createExitBlock(),
			outFalse: createExitBlock(FALSE_SIDE),
		};
	}
};

/**
 * Recursively adds block and it's descendants to
 * the canvas
 *
 * @param {Object} block
 *  Block Object to add to the canvas recursively
 *
 * @returns {void}
 */
const placeBlockRecursive = (block) => {
	// do nothing if
	//  1) block is not given
	//  2) block is already added to canvas
	if (!block || getCanvasBlock(getBlockGuid(block))) return;

	// actually create canvas block
	placeBlock(block);

	// loop over all children
	getBlockChildren(block).forEach((guidOut) => {
		// place child
		placeBlockRecursive(getBlock(guidOut));
	});
};

/**
 * Create (JointJS) Links on the canvas between all
 * blocks that should have a connection
 *
 * @returns {void}
 */
const createLinks = () => {
	// loop over blocks
	Object.values(blocks).forEach((block) => {
		// create link to each parent
		getBlockConnections(block, 'IN').forEach((guidIn) => {
			createLink(guidIn, getBlockGuid(block));
		});
	});
};

/**
 * Creates a Link between two blocks and puts
 * it on the canvas
 *
 * @param {String} srcGuid
 *  GUID of source block
 *
 * @param {String} trgtGuid
 *  GUID of target block
 */
const createLink = (srcGuid, trgtGuid) => {
	const srcCanvasBlock = getCanvasBlock(srcGuid);
	const trgtCanvasBlock = getCanvasBlock(trgtGuid);
	let useFalseExit = false;

	// do nothing if src or trgt canvas block was not found
	if (!srcCanvasBlock || !trgtCanvasBlock) return;

	// set source of Link to source canvas block as default
	let source = srcCanvasBlock;

	// get Block for source GUID
	const srcBlock = getBlock(srcGuid);

	// check if source block splits the flow
	if (isSplittingBlock(srcBlock)) {
		// check if target block should connect to FALSE
		// exit of source block
		useFalseExit =
			srcBlock &&
			getBlockConnections(srcBlock, 'FALSE').indexOf(trgtGuid) !== -1;

		// set Link source to correct embedded exit block
		source = useFalseExit
			? srcCanvasBlock.exits.outFalse
			: srcCanvasBlock.exits.outTrue;
	}

	// get config for where/how to enter target block
	// useful for blocks with multiple entries, to not have
	// touching or overlapping Links
	const trgtEntrypointConfig = getEntryPointConfig(trgtGuid, srcGuid, source);

	// create Link
	const link = new joint.shapes.standard.Link({
		// set source and connect to mid bottom
		// of source block
		// https://resources.jointjs.com/docs/jointjs/v3.4/joint.html#dia.Link.prototype.source
		source: {
			id: source.id,
			anchor: {
				name: 'bottom',
			},
		},
		// set target and connect to top left
		// or target block
		// https://resources.jointjs.com/docs/jointjs/v3.4/joint.html#dia.Link.prototype.target
		target: {
			id: trgtCanvasBlock.id,
			anchor: {
				name: 'topLeft',
				args: {
					// set offset to top left in % of target block
					// width, so '50%' would result in the center
					dx: trgtEntrypointConfig.dx,
				},
			},
		},
		// https://resources.jointjs.com/docs/jointjs/v3.4/joint.html#dia.Link.prototype.attr
		attrs: {
			// make the displayed line the correct
			// color and width
			line: {
				stroke: colors.lightBlue,
				strokeWidth: 3,
			},
		},
		// https://resources.jointjs.com/docs/jointjs/v3.4/joint.html#dia.Link.prototype.connector
		connector: {
			// make the displayed line have the
			// correct shape
			name: 'rounded',
			args: {
				radius: dimensions.linkRadius,
			},
		},
		// https://resources.jointjs.com/docs/jointjs/v3.4/joint.html#dia.Link.prototype.router
		router: {
			// make the displayed line use the
			// correct path
			name: 'manhattan',
			args: {
				step: dimensions.gridSize,
				padding: dimensions.routerPadding,
				startDirections: ['bottom'],
				endDirections: ['top'],
				maxAllowedDirectionChange: 2
			},
		},
		// https://resources.jointjs.com/docs/jointjs/v3.4/joint.html#dia.Link.prototype.vertices
		// from docs at https://resources.jointjs.com/tutorial/links
		// "Vertices are user-defined points on the paper that the link should pass
		// trough on its way from source to target."
		vertices: trgtEntrypointConfig.points,
	});

	// add Link to canvas
	link.addTo(graph);

	putAddButtonOnLink(link, srcGuid, trgtGuid, useFalseExit);
};

/**
 * Places a button to add a new block on top of
 * a given Link
 *
 * @param {Object} link
 *  JointJS Object of Link to place button on
 *
 * @param {String} srcGuid
 *  GUID of source block of link
 *
 * @param {String} trgtGuid
 *  GUID of target block of link
 *
 * @param {Boolean} isFalseExit
 *  Indicates whether the Link originates from the
 *  FALSE exit of the source block
 *
 * @returns {void}
 */
const putAddButtonOnLink = (link, srcGuid, trgtGuid, isFalseExit = false) => {
	// No option to add new blocks in Script flow
	if (isScriptFlow) return;

	const buttonWidth = 20;
	const buttonHeight = 20;

	// https://resources.jointjs.com/docs/jointjs/v3.4/joint.html#linkTools.Button
	const addButton = new joint.linkTools.Button({
		markup: [
			{
				tagName: 'foreignObject',
				attributes: {
					width: buttonWidth,
					height: buttonHeight,
				},
				style: {
					transform: `translate(-${Math.ceil(
						buttonWidth / 2
					)}px, -${Math.ceil(buttonHeight / 2)}px)`,
				},
				children: [
					{
						tagName: 'button',
						// https://stackoverflow.com/a/68027358
						namespaceURI: 'http://www.w3.org/1999/xhtml',
						className:
							'button is-tertiary is-tool is-small is-rounded',
						attributes: {
							type: 'button',
							title: t('fb.buttons.addblock'),
						},
						children: [
							{
								tagName: 'span',
								className: 'icon icon--plus',
							},
						],
					},
				],
			},
		],
		// put it in the middle of the link
		distance: '50%',
		// action that happens on click
		action: function () {
			addNewBlockOnLink(srcGuid, trgtGuid, isFalseExit);
		},
	});

	const toolsView = new joint.dia.ToolsView({
		tools: [addButton],
	});

	const linkView = link.findView(paper);
	linkView.addTools(toolsView);
};

/**
 * Adds a new block on the position of
 * a Link after user clicks that Add button
 * on that Link
 *
 * @param {String} srcGuid
 *  GUID of source block of link
 *
 * @param {String} trgtGuid
 *  GUID of target block of link
 *
 * @param {Boolean} isFalseExit
 *  Indicator whether the link originates
 *  in the FALSE exit of the source block
 *
 * @returns {void}
 */
const addNewBlockOnLink = (srcGuid, trgtGuid, isFalseExit = false) => {
	// dispatch action to Vue Store
	store.dispatch(getStoreAction('ADD_BLOCK_BUTTON_CLICKED', 'FB'), {
		srcGuid,
		trgtGuid,
		isFalseExit,
		isAfterResult: blockComesAfterResult(trgtGuid),
	});
};

/**
 * Function to determine some additional settings
 * for how a link should enter the target block in
 * case the target block has multiple incomings links
 * connecting to it, i.e. is a Result Block
 *
 * Function determines:
 *  dx: 	Percentage that determines where on the top of
 * 			the Block the Link must end, with 0% being far left
 * 			and 100% being far right
 *
 *  points: Array of points the Link must pass through before
 * 			entering the Target Block. Set to prevent Links from
 * 			overlapping and touching.
 *
 * @param {String} trgtGuid
 *  GUID of target Block
 *
 * @param {String} srcGuid
 * 	GUID of source Block
 *
 * @param {Object} srcCanvasBlock
 *  JointJS Object that acts as the source of
 *  the link
 *  N.B. For Check blocks this is either the canvas block
 *  on the True or False port of the Check block, instead
 *  of the Check block itself
 *
 * @returns {Object}
 *  Object with 'dx' and 'points' as keys
 */
const getEntryPointConfig = (trgtGuid, srcGuid, srcCanvasBlock) => {
	// set default return values
	let result = {
		dx: '50%',
		points: [],
	};

	// get source block
	const srcBlock = getBlock(srcGuid);

	// get target block
	const trgtBlock = getBlock(trgtGuid);

	// get parent(s) of target block (i.e. entries)
	const entries = getBlockConnections(trgtBlock, 'IN');

	// return if target block does NOT have multiple entries
	// AND source block is not a splitting block
	// so in this case the link will connect to the top middle of
	// the target block (dx: 50%) and the link will NOT be forced
	// through specific points
	if (entries.length <= 1 && !isSplittingBlock(srcBlock)) {
		return result;
	}

	// helper function to set the dx offset to return
	const setDx = (value) => {
		// set determined position as percentage
		result.dx = `${value * 100}%`;
	};

	// get target canvas block
	const trgtCanvasBlock = getCanvasBlock(trgtGuid);

	// get X coord of source and target blocks
	const srcX = getBlockPosition(srcBlock, 'x');
	const trgtX = getBlockPosition(trgtBlock, 'x');

	// value is:
	//  -1 -> if source X coord is lower (so to the left) than target
	//   0 -> if source X coord equals target
	//   1 -> if source X coord is higher (so to the right ) than target
	const srcColSide = srcX < trgtX ? -1 : srcX > trgtX ? 1 : 0;

	// get Boolean whether source block is a splitting block
	const srcIsSplitting = isSplittingBlock(srcBlock);

	const entryCols = [];

	// loop over blocks that have connection to target block
	entries.forEach((entryGuid) => {
		// add X coord of source block to collection
		entryCols.push(getBlockPosition(getBlock(entryGuid), 'x'));
	});

	// sort X coords of source blocks ascending
	entryCols.sort((a, b) => a - b);

	// get index of current source block in ordered
	// list of source blocks X coords
	const srcBlockColIndex = entryCols.indexOf(srcX);

	// get offset of entry point on target block as percentage
	// of width of target block width 0% being far left, 100% far right
	const dxTarget = Number(
		((1 / (entryCols.length + 1)) * (srcBlockColIndex + 1)).toFixed(2)
	);

	// check if src and trgt are on the same X coord and
	// source is splitting which means the link originates
	// not from the center of the block itself but from
	// the block on either the TRUE or FALSE port of the block
	if (srcColSide === 0 && srcIsSplitting) {
		// calculate position percentage on target block to match
		// X coord of center of src canvas block
		const dxStraightLine = Number(
			(
				(srcCanvasBlock.position().x +
					srcCanvasBlock.size().width / 2 -
					trgtCanvasBlock.position().x) /
				trgtCanvasBlock.size().width
			).toFixed(2)
		);

		// use dX for straight line if it is HIGHER than the
		// dX would be based on the index of the entry
		if (dxStraightLine >= dxTarget) {
			setDx(dxStraightLine);

			// return without adding additional points to let
			// link pass through, since the link is a straight line
			return result;
		}
	}

	// set dx for result
	setDx(dxTarget);

	// Things to keep in mind:
	//
	// 1) Only a Result Block can have multiple entries
	//
	// 2) Result Block is always positioned at the
	//    same X coord as the Start Block
	//
	// 3) For the Result Block to have multiple entries,
	//    the Flow must have diverged at some point (i.e. with a Check block)
	//
	// 4) Once the Flow has diverged (so AFTER the first Check block) no other
	//    Blocks will ever have the same X coord as Start/Result anymore
	//
	// 5) A Check Block can only be directly connected to a Result block with it's
	//    TRUE port. The FALSE port will link to an Error block (which stops the flow)
	//    or to any normal block
	//
	// 6) Based on point 1-5: All incoming Links to a Block will come from
	//    different X coords
	//
	// 7) Based on point 1-6: If a Block has multiple entries, only one of those can
	//	  come from the same X coord as the Block itself, namely the TRUE port of the
	//    first Check block in the Flow
	//
	// 8) Based on point 7: If a Block has multiple entries, including one from the
	//    same X coord as the Block itself, there will for sure be no other entry that
	//    originates from a higher X coord, since the TRUE port of the first Check block
	//    is directly connected to Result block and so the flow does not diverge further
	//    to the right of the canvas
	//

	// The issue with having multiple entry points is that the links connecting
	// the source blocks to those point can easily overlap or touch.
	//
	// For instance if 2 blocks are on the left side of the canvas, both links
	// might go straight down as far as possible, then turn right at the Y coord
	// of the target block, therefor following the same horizontal path to the target.
	//
	// With the code below we come up with points to lead the link through to
	// prevent them from touching.
	//
	// We do this by coming up with two points:
	//
	// 1) Directly above the entrypoint on the target block. Remember that most left
	//	  source block will also have most left entry point on the target block, and so on.
	//	  So defining a point at N * Y above that entry point, where N means Nth source block
	//	  from the left or right, and Y is a fixed value, should give each link a point above
	//	  the target block like these x's:
	//
	//          x
	//        x | x
	//      x | | | x
	//    x | | | | | x
	//   _|_|_|_|_|_|_|_
	//   |    Target    |
	//   ---------------
	//
	// 2) Directly below the exit point on the source block, at the same Y as 1)
	//
	// So with the 2 points combined, there is only 1 path for each link possible:
	// first straight down to point 2, then sideways to point 1, then down to the
	// entry point on target block

	// determine the N (multiplier) from the explanation above
	// note that it will only have a non-zero value if the
	// target block has multiple entries
	let verticalMultiplier = 0;
	if (entries.length > 1) {
		verticalMultiplier =
			srcColSide === -1
				? srcBlockColIndex
				: entryCols.length - srcBlockColIndex - 1;
	}

	// create point 1) from explanation
	const linkPoint1 = {
		// y determined by N times a fixed value
		// for that fixed value we use the same pixel size as used
		// for the link router padding
		y: trgtCanvasBlock.position().y -
			(verticalMultiplier + 2) * dimensions.routerPadding,
		// x directly above entry point
		x: trgtCanvasBlock.position().x +
			dxTarget * trgtCanvasBlock.size().width,
	};

	// create point 2) from explanation
	const linkPoint2 = {
		// same y as point 1), minus 2* link radius to allow enough space for the link to make
		// a rounded corner
		y: linkPoint1.y - 2 * (dimensions.linkRadius),
		x: srcCanvasBlock.position().x + srcCanvasBlock.size().width / 2, // middle of source block,
	};

	// add point 2)
	//
	// this point is added to the array first because the points
	// are passed through by the Link in order, starting from
	// the source element
	result.points.push(new joint.g.Point(linkPoint2.x, linkPoint2.y));
	

	// add point 1)
	result.points.push(new joint.g.Point(linkPoint1.x, linkPoint1.y));

	return result;
};

/**
 * Calculate X and Y positions for all blocks
 * before actually creating the blocks on the Canvas
 *
 * @returns {void}
 */
const calculatePositionsForAllBlocks = () => {
	if (isEmpty(blocks)) return;

	// add info about child columns to all blocks
	// between Start and Result block
	const resBlock = getResultBlock();
	addChildColumnsToBlocks(resBlock);

	// get all error blocks
	const errorBlocks = getErrorBlocks();
	if (errorBlocks) {
		// loop over close blocks
		errorBlocks.forEach((errorBlock) => {
			// add info about child columns to all blocks
			// between Start and this Error block
			addChildColumnsToBlocks(errorBlock);
		});
	}

	// get all close blocks
	const closeBlocks = getCloseBlocks();
	if (closeBlocks) {
		// loop over close blocks
		closeBlocks.forEach((closeBlock) => {
			// add info about child columns to all blocks
			// between Result and this Close block
			addChildColumnsToBlocks(closeBlock);
		});
	}

	// reset max depth
	maxDepth = 0;

	// calculate and set x & y positions for
	// all blocks between Start and Result block
	const startBlock = getStartBlock();
	const resultBlock = getResultBlock();
	setPositionRecursive(startBlock, 0, false, 0, resultBlock);

	// calculate and set x & y positions for
	// all blocks below Result block
	// this is done separately because Error blocks
	// do not link to Result block, but Y coord of
	// Result block should still be higher than all
	// blocks that come before it
	// so at first all Y coords for all blocks before
	// the Result block are calculated, and then the
	// Y coord for the Result block is set to the max
	// depth so far, plus a fixed value (except for Script flows)
	setPositionRecursive(resultBlock, maxDepth + (isScriptFlow ? 1 : 1.5));
};

/**
 * Recursively calculates position for block
 * and it's descendants
 *
 * @param {Object} block
 *  Block Object to set position for
 *
 * @param {Int} depth
 * 	Current Y offset, so the n-th row where the Block
 *  should be placed
 *
 * @param {String} blockSide
 *  Either left, right or false. Depending on whether
 *  the Block is to the left, the right or directly
 *  beneath it's parent
 *
 * @param {Int} parentX
 *  X coordinate of parent
 *
 * @param {Object} stopBlock
 *  Block Object where to stop recusively adding
 *  position
 *
 * @returns {void}
 */
const setPositionRecursive = (
	block,
	depth = 0,
	blockSide = false,
	parentX = 0,
	stopBlock = false
) => {
	if (stopBlock && block === stopBlock) return;

	// set max reached depth
	maxDepth = Math.max(depth, maxDepth);

	// set Y to given depth if current Y for Block is
	// less than given depth
	setCoord(block, 'y', Math.max(depth, getBlockPosition(block, 'y')));

	// X position for blocks on the central axis
	// will always be 0
	if (isCentralAxisBlock(block)) {
		setCoord(block, 'x', 0);
	} else {
		// copy parent X coord
		setCoord(block, 'x', parentX);

		// check if block is NOT directly below parent
		// but to the left or right (so after splitting block)
		if (blockSide !== false) {
			const colsToAdd =
				blockSide === FALSE_SIDE
					? -1 * (1 + getBlockChildCol(block, 'COL_TRUE'))
					: 1 + getBlockChildCol(block, 'COL_FALSE');

			// if so, update the x coord with the correct
			// amount of columns
			setCoord(block, 'x', getBlockPosition(block, 'x') + colsToAdd);
		}
	}

	const setChildPosition = (guid, side) => {
		setPositionRecursive(
			getBlock(guid),
			depth + 1,
			side,
			getBlockPosition(block, 'x'),
			stopBlock
		);
	};

	// loop over direct or TRUE descendants
	getBlockConnections(block, 'OUT').forEach((guidOut) => {
		// set child positions
		setChildPosition(
			guidOut,
			isSplittingBlock(block) ? TRUE_SIDE : false // on the TRUE side or directly below
		);
	});

	// loop over FALSE descendants
	getBlockConnections(block, 'FALSE').forEach((guidOutFalse) => {
		// set child on FALSE side position
		setChildPosition(guidOutFalse, FALSE_SIDE);
	});
};

/**
 * Function starts the logic of getting the
 * correct position on the canvas for every
 * Block.
 * Logic starts with the given block and works
 * its way up through each path until the path
 * goes into a 'central axis' block
 *
 * @param {Object} start
 *  Block Object to start from
 *
 * @returns {void}
 */
const addChildColumnsToBlocks = (start) => {
	if (start) {
		// recursively calculate child columns for every
		// block that connects to result block
		getBlockConnections(start, 'IN').forEach((guid) => {
			calculateChildColumnsForBlockRecursive(guid, getBlockGuid(start));
		});
	}
};

/**
 * Function to calculate the amount of child 'columns' that
 * are to the bottom left and right of a Block with a given GUID
 *
 * A Check Block splits the flow stream in two streams. So you can say
 * a normal Check Block has 1 child column on the left, and one on the right.
 * Another Check Block further down in one of those streams, affects the
 * columns the first Check Block has on the left or right. Therefor this
 * calculation is done from the bottom up, starting with the Result Block.
 *
 * @param {String} guidBlock
 *  GUID of Block to calculate left
 *  left and right columns for
 *
 * @param {String} guidChild
 * 	GUID of previous Block on the upward
 * 	tracing of the flow path
 *
 * @return {void}
 */
const calculateChildColumnsForBlockRecursive = (
	guidBlock,
	guidChild = false
) => {
	// get block object
	const block = getBlock(guidBlock);

	// return if no block was found
	if (!block) return;

	// get child block object
	const child = getBlock(guidChild);

	// check if block splits flow
	if (isSplittingBlock(block) && child) {
		// get totals column count for child, so
		//  1) 1 for child block itself
		//  2) columns on left of child
		//  3) columns on right of child
		const totalChildCols =
			1 +
			getBlockChildCol(child, 'COL_FALSE') +
			getBlockChildCol(child, 'COL_TRUE');

		// find out on what side of the block the child is on
		const childSide =
			getBlockConnections(block, 'FALSE').indexOf(guidChild) > -1
				? 'COL_FALSE'
				: 'COL_TRUE';

		// update child cols on that side of the block
		setChildCol(block, childSide, totalChildCols);
	} else if (child) {
		// if block:
		//  1) does not split the stream
		//  2) has a child
		//  3) this child has child columns set
		// copy childs child columns to this block
		setChildCol(block, 'COL_TRUE', getBlockChildCol(child, 'COL_TRUE'));
		setChildCol(block, 'COL_FALSE', getBlockChildCol(child, 'COL_FALSE'));
	}

	// stop recursive calculation if current block is a
	// 'central' axis block like Start or Result
	if (!isCentralAxisBlock(block)) {
		getBlockConnections(block).forEach((guidIn) => {
			// do the same column calculation again for each parent
			calculateChildColumnsForBlockRecursive(guidIn, guidBlock);
		});
	}
};

/**
 * Helper functions
 */

/**
 * Get the Start Block Object
 *
 * @returns {Object/Boolean}
 *  Start Block Object or false
 */
const getStartBlock = () => {
	return getBlock(store.getters[getStoreGetter('START_GUID', 'BLOCKS')]);
};

/**
 * Get the Result Block Object
 *
 * @returns {Object/Boolean}
 *  Result Block Object or false
 */
const getResultBlock = () => {
	return getBlock(store.getters[getStoreGetter('RESULT_GUID', 'BLOCKS')]);
};

/**
 * Get all Error Block Objects
 *
 * @returns {Array/Boolean}
 *  Array of Error Block Objects or false
 */
const getErrorBlocks = () => {
	return getBlockByType('ERROR', true);
};

/**
 * Get all Close Block Objects
 *
 * @returns {Array/Boolean}
 *  Array of Close Block Objects or false
 */
const getCloseBlocks = () => {
	return getBlockByType('CLOSE', true);
};

/**
 * Get the Block Object for a Block
 * with a given GUID
 *
 * @param {String} guid
 *  GUID of requested Block
 *
 * @returns {Object/Boolean}
 *  Block Object or false
 */
const getBlock = (guid) => {
	return store.getters[getStoreGetter('BLOCK_BY_GUID', 'BLOCKS')](guid);
};

/**
 * Helper function to get all or a single Block
 * Object of a requested type
 *
 * @param {String} type
 * 	Type of Block(s) to return
 *
 * @param {Boolean} getAll
 *  Indicates whether to return all Blocks
 *  of given type, or only the first match
 *
 * @returns {Array/Object/Boolean}
 *  Either array of Block objects, a single Block Object
 *  or false if none found
 */
const getBlockByType = (type, getAll = true) => {
	const blocks =
		store.getters[getStoreGetter('BLOCKS_BY_TYPE', 'BLOCKS')](type);
	return blocks.length > 0 ? (getAll ? blocks : blocks[0]) : false;
};

/**
 * Checks if a block with a given GUID comes
 * after the Result block in the flow
 *
 * @param {String} guid
 *  GUID of block to check
 *
 * @returns {Boolean}
 */
const blockComesAfterResult = (guid) => {
	const block = getBlock(guid);
	const resBlock = getResultBlock();

	return (
		block &&
		resBlock &&
		getBlockPosition(block, 'y') > getBlockPosition(resBlock, 'y')
	);
};

/**
 * Helper function to update the X or Y
 * coordinate of a given Block
 * Actual change is done by committing a mutation
 * to the Vuex Store
 *
 * @param {Object} block
 *  Block to update
 *
 * @param {String} pos
 *  Coordinate to update, either 'x' or 'y'
 *
 * @param {Int} value
 *  Value to update coord to
 *
 * @returns {void}
 */
const setCoord = (block, pos = 'x', value = 0) => {
	store.commit(getStoreMutation('UPDATE_BLOCK_POSITION', 'BLOCKS'), {
		guid: getBlockGuid(block),
		pos,
		value,
	});
};

/**
 * Helper function to update the count of child columns
 * a given Block has on either the left or right side
 * Actual change is done by committing a mutation
 * to the Vuex Store
 *
 * @param {Object} block
 *  Block to update
 *
 * @param {String} side
 *  Side to update, either 'COL_TRUE' or 'COL_FALSE'
 *
 * @param {Int} count
 *  Value to update to
 *
 * @returns {void}
 */
const setChildCol = (block, side = 'COL_TRUE', count = 0) => {
	store.commit(getStoreMutation('UPDATE_BLOCK_CHILD_COL', 'BLOCKS'), {
		guid: getBlockGuid(block),
		side,
		count,
	});
};

/**
 * Gets the Canvas Block Instance for
 * a Block with a given GUID
 *
 * @param {String} guid
 *  GUID of requested Block
 *
 * @returns {Object/Boolean}
 *  Block Object or false
 */
const getCanvasBlock = (guid) => {
	return guid && canvasBlocks && canvasBlocks[guid]
		? canvasBlocks[guid]
		: false;
};

/**
 * Helper function to get the starting X position
 * for the flow
 *
 * @returns {Int}
 */
const getStartX = () => {
	if (!$container) return 0;
	return Math.round($container.offsetWidth / 2 - dimensions.blockWidth / 2);
};

export default {
	init,
	initFlow,
	unmount,
};
