<template>
	<div class="app-layer is-active" :class="{ 'is-invisible': overlayActive }">
		<TheCanvas v-if="canReadFlow && !overlayActive" />
	</div>
	<div
		class="overlay-layer app-layer"
		:class="{ 'is-active': overlayActive }"
	>
		<NewFlow v-if="showNewFlowModal" />

		<TheFlowList v-if="showFlowList" />

		<TheBlockDetails v-if="showBlockDetails" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoreAction, getStoreGetter } from '@assets/scripts/store/config';
import usePermission from '@assets/scripts/composables/usePermission';

import TheCanvas from '@modules/FlowBuilder/materials/structures/TheCanvas.vue';
import TheBlockDetails from '@modules/FlowBuilder/materials/structures/TheBlockDetails.vue';
import TheFlowList from '@modules/FlowBuilder/materials/structures/TheFlowList.vue';

import NewFlow from '@modules/FlowBuilder/materials/components/NewFlow.vue';

export default {
	name: 'TheFlowBuilder',
	components: {
		TheCanvas,
		TheBlockDetails,
		NewFlow,
		TheFlowList,
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether the form to
			 * create a new flow should be displayed
			 */
			showCreateNew: getStoreGetter('SHOW_CREATE_NEW', 'FB'),
			/**
			 * Boolean to indicate whether the canvas
			 * should be displayed
			 */
			flowActive: getStoreGetter('FLOW_ACTIVE', 'FLOW'),
			/**
			 * Boolean to indicate whether the block
			 * details overlay should be displayed
			 */
			blockActive: getStoreGetter('BLOCK_ACTIVE', 'BLOCKS'),
		}),
		/**
		 * Boolean to indicate whether the current
		 * user can create flows, which is based on the same
		 * permission as editing
		 */
		canCreateFlow: function () {
			return usePermission('Upsert', 'FlowBuilder');
		},
		/**
		 * Boolean to indicate whether the current
		 * user can read (view) flows
		 */
		canReadFlow: function () {
			return usePermission('Read', 'FlowBuilder');
		},
		showNewFlowModal: function () {
			return this.showCreateNew && this.canCreateFlow;
		},
		showFlowList: function () {
			return !this.showNewFlowModal && !this.flowActive && this.canReadFlow;
		},
		showBlockDetails: function () {
			return !this.showFlowList && this.blockActive && this.canReadFlow;
		},
		overlayActive: function () {
			return this.showNewFlowModal || this.showBlockDetails || this.showFlowList;
		},
	},
	unmounted: function () {
		// reset store to default state when page is unmounted
		this.$store.dispatch(getStoreAction('RESET', 'FB'));
	},
	mounted: function () {
		this.$store.dispatch(getStoreAction('CLEAR_VALIDATION'));
	},
};
</script>
