<template>
	<VOverlay
		:hasBackground="false"
		:hasJsonButton="false"
		:hasCloseButton="false"
		:closeOnBackgroundClick="false"
		:isLarge="true"
		:isVCentered="false"
		:hasFullwidthBody="true"
		:hasScrollableContent="true"
		v-model="overlayIsActive"
		:title="$t('fb.welcomeFlow.title')"
		:useFocusTrap="false"
		:closeWithEscape="false"
	>
		<template v-if="canCreateFlow" #header>
			<VButton
				class="has-margin-top-1 has-margin-bottom-2"
				:text="$t('fb.welcomeFlow.newFlowButton')"
				@clicked="createNewClicked"
			/>
		</template>

		<div class="content-header has-padding has-bottom-divider">
			<div class="field is-grouped is-flex-grow-1">
				<VField
					:label="$t('fb.welcomeFlow.restApis')"
					:isFloating="true"
					class="has-margin-right-1"
					:class="{ 'is-active': apiValue }"
				>
					<VSelect :options="apiOptions" v-model="apiValue" />
				</VField>
				<VField
					:label="$t('fb.welcomeFlow.status')"
					:isFloating="true"
					class="has-margin-right-1"
					:class="{ 'is-active': statusValue }"
				>
					<VSelect
						:options="statusOptions"
						v-model="statusValue"
					/>
				</VField>

				<VSearch v-model="search" />
			</div>
		</div>

		<div class="scrollable-content">
			<SortableDataTable
				:rowClickedFn="rowClicked"
				defaultSort="last_time_edited"
				sortDirection="DESC"
				:scrollable="false"
				:data="filteredData"
				:columns="columns"
				:emptyText="$t('fb.welcomeFlow.table.noResults')"
			/>
		</div>
	</VOverlay>
</template>

<script>
import { computed, reactive, toRefs } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { getStoreAction, getStoreMutation } from '@assets/scripts/store/config';
import { formatForTable } from '@modules/FlowBuilder/components/flow';
import Helpers from '@assets/scripts/helpers';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { useCreateNewOnKeydown } from '@assets/scripts/composables/useCreateNewOnKeydown';
import usePermission from '@assets/scripts/composables/usePermission';
import {
	GET_RESTFLOWS,
	GET_REST_APIS,
} from '@modules/FlowBuilder/endpoints';

export default {
	name: 'TheFlowList',
	setup() {
		const { t } = useI18n();
		const store = useStore();

		const state = reactive({
			overlayIsActive: true,
			// default value for REST API filter
			apiValue: t('general.all'),
			// values for REST API filter, will be extended
			// on mount with all available REST API's
			apiOptions: [
				{
					value: t('general.all'),
					text: t('fb.welcomeFlow.filter.all'),
				},
			],
			/**
			 * Boolean to indicate whether the current
			 * user can create flows, which is based on the same
			 * permission as editing
			 */
			canCreateFlow: usePermission('Upsert', 'FlowBuilder'),
			// default value for Status filter
			statusValue: t('general.all'),
			// values for Status filter
			statusOptions: [
				{
					value: t('general.all'),
					text: t('general.all'),
				},
				{
					value: t('general.draft'),
					text: t('general.draft'),
				},
				{
					value: t('general.published'),
					text: t('general.published'),
				},
				{
					value: t('general.archived'),
					text: t('general.archived'),
				},
				{
					value: t('general.inactive'),
					text: t('general.inactive'),
				},
			],
			search: '',
			// all REST Flows, will be extended
			// on mount with all available REST Flows
			restFlows: [],
		});

		const createNewClicked = () => {
			store.dispatch(
				getStoreAction('TOGGLE_CREATE_NEW', 'FB'),
				true
			);
		};
		if(state.canCreateFlow) {
			useCreateNewOnKeydown(createNewClicked);
		}

		// definition of table columns
		const columns = [
			{
				label: t('fb.welcomeFlow.table.columns.flow_name'),
				field: 'flow_name',
				sortable: true,
				searchable: true,
				cellClass: 'has-text-weight-semibold',
				width: '30%',
			},
			{
				label: t('fb.welcomeFlow.table.columns.rest_api'),
				field: 'rest_api_name',
				sortable: true,
				searchable: true,
				width: '15%',
			},
			{
				label: t('fb.welcomeFlow.table.columns.method'),
				field: 'method',
				sortable: true,
				searchable: true,
				width: '15%',
			},
			{
				label: t('fb.welcomeFlow.table.columns.last_time_edited'),
				field: 'last_time_edited',
				sortable: true,
				searchable: true,
				isDate: true,
				width: '25%',
			},
			{
				label: t('fb.welcomeFlow.table.columns.status'),
				field: 'status',
				sortable: true,
				searchable: true,
				width: '15%',
			},
		];

		// get names of columns that are searchable
		const searchableCols = Helpers.getSearchableColumns(columns);

		// sets the current flow and flow name as local variable
		const setFlows = async () => {
			state.restFlows = [];

			// get all REST Flows
			const restFlows = await useApiAsync(GET_RESTFLOWS);

			if (restFlows && restFlows.length > 0) {
				// process flows into table columns
				restFlows.forEach((flow) => {
					state.restFlows.push(formatForTable(flow));
				});
			}
		};

		const filteredData = computed(() => {
			// loop over all flows
			const newData = state.restFlows.filter((row) => {
				// filter on REST API and on Status
				return (
					Helpers.filterByValue(
						row,
						'rest_api',
						state.apiValue,
						t('general.all')
					) &&
					Helpers.filterByValue(
						row,
						'status',
						state.statusValue,
						t('general.all')
					)
				);
			});

			// return if no rows match
			if (state.search.length === 0) return newData;

			// filter on search string
			return Helpers.filterByString(
				newData,
				state.search,
				searchableCols
			);
		});

		const rowClicked = (row) => {
			if (row.guid) {
				// open drawer with flow information
				store.commit(getStoreMutation('OPEN_DRAWER'), {
					type: 'flowDetails',
					data: row.guid,
				});
			}
		};

		// subscribe to state updates of Store
		const unsubscribeAction = store.subscribe((mutation) => {
			// act on any flow being updated
			if (
				mutation.type !== getStoreMutation('FLOW_DELETED', 'FLOW') &&
				mutation.type !== getStoreMutation('FLOW_STATUS_UPDATED', 'FLOW')
			)
				return;

			// get flows from store after mutations
			setFlows();
		});

		onMounted(async () => {
			// get flows from store on load
			setFlows();

			// get all REST API's
			const restApis = await useApiAsync(GET_REST_APIS);

			if (restApis && restApis.length > 0) {
				// map REST API objects to use in select field
				const mappedRestApis = Helpers.mapObjectArrayForSelect(
					restApis,
					{
						key: 'guid',
						text: 'name',
					}
				);

				// add options to select field
				state.apiOptions.push(...mappedRestApis);
			}
		});

		return {
			...toRefs(state),
			rowClicked,
			columns,
			searchableCols,
			filteredData,
			createNewClicked,
			unsubscribeAction
		};
	},
	methods: {
		sortByDate(a, b, isAsc) {
			return Helpers.date.sortByIsoDate(a.time, b.time, isAsc);
		},
	},
	unmounted: function( ) {
		// unsubscribe from actions
		this.unsubscribeAction();
	},
};
</script>
