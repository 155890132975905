<template>
	<div
		class="content-header has-padding has-bottom-divider is-flex is-flex-grow-0 is-align-items-flex-end"
	>
		<VField
			:label="$t('fb.blockDetails.writeBlock.chooseConnection')"
			:isFloating="true"
			:class="{ 'is-active': config.conn_guid }"
			class="has-padding-right-1"
		>
			<VSelect
				:options="connectionOptions"
				v-model="config.conn_guid"
				@change="() => connectionUpdated()"
				:disabled="!editMode"
			/>
		</VField>
		<VField
			:label="$t('fb.blockDetails.writeBlock.chooseAction')"
			:isFloating="true"
			:class="{ 'is-active': config.guid }"
		>
			<VSelect
				:options="actionOptions"
				v-model="config.guid"
				@change="() => actionUpdated()"
				:disabled="!editMode || !config.conn_guid"
			/>
		</VField>

		<VSearch v-model="search" />
	</div>
	<SortableDataTable
		:hoverable="false"
		defaultSort="to"
		:data="filteredData"
		:columns="columns"
		:emptyText="$t('fb.blockDetails.writeBlock.table.noResults')"
		tableClasses="scrollable-content"
	>
		<template
			v-for="(row, key) in tableFields"
			:key="key"
			#[`from-${key}`]
		>
			<template v-if="editMode">
				<VField
					:label="$t('fb.blockDetails.writeBlock.field')"
					:isFloating="true"
					:class="{
						'is-active': config.mapping[key]['from'],
					}"
					v-if="config.mapping[key]"
				>
					<VSelect
						:options="fieldOptions(row.type, fields)"
						:hasUnsetOption="true"
						v-model="config.mapping[key]['from']"
						@change="mappingUpdated"
					/>
				</VField>
			</template>
			<template v-else>
				{{
					getNameAsPath(config.mapping[key]['from']) ||
					$t('general.dash')
				}}
			</template>
		</template>
	</SortableDataTable>
</template>

<script>
import { mapGetters } from 'vuex';
import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import { getBlockGuid, getBlockConfig } from '@modules/FlowBuilder/components/block';
import {
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { composeDisplayName } from '@modules/FlowBuilder/components/actions';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import {
	GET_ACTIONS,
	GET_ACTION_BY_ID,
} from '@modules/FlowBuilder/endpoints';
import { log } from '@assets/scripts/components/notifications';
import { GET_CONNECTIONS } from '@assets/scripts/api/config';

export default {
	name: 'WriteBlockConfig',
	props: {
		description: {
			type: null,
		},
		preprocess: {
			type: null
		},
	},
	data: function () {
		return {
			connectionOptions: [],
			search: '',
			availableActions: [],
			currentAction: false,
			mappingCache: {},
			fieldOptions: Field.createFieldOptions,
			columns: [
				{
					label: this.$t('fb.functions.mapping.columns.systemField'),
					field: 'to',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('fb.functions.mapping.columns.flowField'),
					field: 'from',
					sortable: false,
					searchable: false,
					default: this.$t('general.dash'),
					component: 'VSelect',
				},
				{
					label: this.$t('fb.functions.mapping.columns.required'),
					field: 'required',
					sortable: true,
					searchable: false,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('fb.functions.mapping.columns.type'),
					field: 'field_type',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('fb.functions.mapping.columns.key'),
					field: 'is_key',
					sortable: true,
					searchable: false,
					default: this.$t('general.dash'),
				},
			],
		};
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate if flow is in Edit mode
			 */
			editMode: getStoreGetter('EDIT_MODE', 'FLOW'),
			/**
			 * Get block that is currently viewed/edited
			 */
			block: getStoreGetter('CURRENT_BLOCK', 'BLOCKS'),
		}),
		/**
		 * Get desctructured config of block
		 */
		config: function () {
			return getBlockConfig(this.block);
		},
		/**
		 * Determine current available input fields for the block
		 * These fields come from the Flow State
		 */
		fields: function () {
			const guid = getBlockGuid(this.block);

			// return empty array if no block guid found
			if (!guid) return [];

			// get calculated input for block
			return this.$store.getters[getStoreGetter('BLOCK_INPUT', 'BLOCKS')](
				guid
			);
		},
		tableFields: function () {
			const rows = [];

			if (!this.currentAction) return rows;

			// loop over input fields for Action
			this.currentAction.input.forEach((row, index) => {
				rows.push({
					to: Field.getNameAsPath(row.name),
					from: this.config.mapping[index] && this.config.mapping[index]['from'] ?
						this.fieldNameViewOnly(this.config.mapping[index]['from']) :
						this.$t('general.dash'),
					required: row.validation.required
						? this.$t('general.yes')
						: this.$t('general.no'),
					type: row.type,
					field_type: Field.translateVarType(row.type),
					is_key: row.key
						? this.$t('general.yes')
						: this.$t('general.no'),
					key: index,
				});
			});

			return rows;
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// return all if no search entered
			if (this.search.length === 0) return this.tableFields;

			// filter on search string
			return Helpers.filterByString(
				this.tableFields,
				this.search,
				this.searchableCols
			);
		},
		actionOptions: function () {
			if (!this.availableActions || this.availableActions.length < 1)
				return [];

			const options = [];

			this.availableActions.forEach((row) => {
				options.push({
					guid: row.guid,
					name: composeDisplayName(row),
				});
			});

			// map available actions into list for VSelect
			return Helpers.mapObjectArrayForSelect(options, {
				key: 'guid',
				text: 'name',
			});
		},
	},
	mounted: async function () {
		// get all available connections
		const connections = await useApiAsync(GET_CONNECTIONS);

		if (connections && connections.length > 0) {
			// map Connection objects to use in select field
			this.connectionOptions = Helpers.mapObjectArrayForSelect(connections, {
				key: 'conn_guid',
				text: 'name',
			})
		}

		// load chosen action meta
		await this.getAction();

		// get available Write actions
		await this.getAndSetAvailableActions()

		this.setMappingCache();

		this.actionUpdated(true);
	},
	methods: {
		getAndSetAvailableActions: async function () {
			if (!this.config.conn_guid) {
				// if no connection guid do not do the api call and set availableActions to an empty array
				this.availableActions = [];
				return;
			}
			// get available Write actions
			const actions = await useApiAsync(GET_ACTIONS, {
				keys: {
					connection: this.config.conn_guid
				},
				parameters: {
					read: false
				}
			});
			this.availableActions = actions? actions : []
		},
		getNameAsPath: function (input) {
			return Field.getNameAsPath(input);
		},
		markModified: function () {
			// mark block as modified whenever a change to
			// the fields is made
			this.$store.commit(
				getStoreMutation('BLOCK_CONFIG_MODIFIED', 'BLOCKS')
			);
		},
		actionUpdated: async function (initial = false) {
			// load chosen action meta
			if (!initial) await this.getAction();

			// set mapping for new action
			this.setMapping();

			// set auto mapping when user change the action documnet
			if (!initial) this.setAutoMapping();

			// get some data from chosen action to copy to block config
			const { method, name, obj_guid, type } = this.currentAction || {};
			Object.assign(this.config, { method, name, obj_guid, type });

			if (!initial) this.markModified();
		},
		connectionUpdated: async function () {
			// reset chosen action values
			Object.assign(this.config, { method: '', name: '', obj_guid: '', type: '', guid: '' });
			this.currentAction = false

			// get available Write actions
			await this.getAndSetAvailableActions()

			this.markModified();
		},
		getAction: async function () {
			if (!this.config.guid) {
				// unset current action and return
				this.currentAction = false;
				return;
			}

			// load current action from nominow api
			const action = await useApiAsync(GET_ACTION_BY_ID, {
				keys: {
					guid: this.config.guid
				}
			});

			this.currentAction = action || false;

			const { conn_guid } = this.currentAction || {};
			Object.assign(this.config, { conn_guid });
		},
		// select the field option that have the same name and type 
		// of the document field automatically
		setAutoMapping: function () {
			const autoMappingCounter = Field.setAutoMapping(this.currentAction.input, this.config.mapping, this.fields);
			if (autoMappingCounter > 0) {
				// log the amount of fields that are auto mapped
				log(this.$t('info.autoMapping', { count: autoMappingCounter }), 'info');
			}
		},
		setMapping: function () {
			this.config.mapping = [];

			if (
				this.currentAction &&
				this.currentAction.input &&
				this.currentAction.input.length > 0
			) {
				this.currentAction.input.forEach((row) => {
					this.config.mapping.push(
						this.getFromMappingCache(row.name)
					);
				});
			}
		},
		fieldNameViewOnly: function (name) {
			if (!name) return this.$t('general.dash');
			return Field.getNameAsPath(name);
		},
		setMappingCache: function () {
			if (!this.config.guid) return;

			if (!this.mappingCache[this.config.guid]) {
				this.mappingCache[this.config.guid] = [];
			}

			// update cache, used when switching between Actions
			this.mappingCache[this.config.guid] = Helpers.cloneObject(
				this.config.mapping
			);
		},
		mappingUpdated: function () {
			this.setMappingCache();
			this.markModified();
		},
		getFromMappingCache: function (name) {
			const result = {
				to: name,
				from: '',
			};

			if (!this.config.guid || !this.mappingCache[this.config.guid])
				return result;

			if (this.mappingCache[this.config.guid]) {
				const cached = this.mappingCache[this.config.guid].filter(
					(row) => row.to === name
				);

				if (cached.length > 0) {
					result.from = cached[0].from || '';
				}
			}

			return result;
		},
	},
};
</script>
