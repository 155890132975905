<template>
	<VOverlay
		class="new-flow-modal"
		:title="$t('fb.newFlow.title')"
		:size="2"
		:isVCentered="false"
		:closeOnBackgroundClick="true"
		:hasBackground="false"
		:modelValue="true"
		@update:modelValue="overlayClosed"
		:modalHasSharpCorner="true"
		@keydown.meta.s.prevent.stop="submit"
	>
		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('fb.newFlow.whatName')" />
		<VInputString
			:isRequired="true"
			v-model:valueModel="flowNameValue"
			:trim="true"
			:trimFunction="trimFlowName"
			:label="$t('fb.newFlow.nameInputLabel')"
		/>
		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('fb.newFlow.whatMethod')" />

		<VField
			class="is-fullwidth"
			:isGrouped="true"
		>
			<VOption
				v-for="(item, key) in methodOptions"
				:isButton="true"
				:key="key"
				:value="item.id"
				:label="item.label"
				v-model="methodValue"
				type="radio"
			/>
		</VField>

		<VField
			v-if="canEditSpecialFlow"
			class="is-fullwidth"
			:help="$t('fb.newFlow.isScriptInfo')"
		>
			<VOption
				:label="$t('fb.newFlow.isScript')"
				v-model="isScripting"
				type="checkbox"
			/>
		</VField>

		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('fb.newFlow.whatApi')" />
		<VField
			:label="$t('fb.newFlow.selectApi')"
			:isFloating="true"
			:class="{ 'is-active': apiValue }"
		>
			<VSelect :options="apiOptions" v-model="apiValue" />
		</VField>
		<template #footer>
			<VButton
				:text="$t('fb.newFlow.submitButton')"
				icon="chevron-right"
				:iconOnRight="true"
				classes="button-modal-footer"
				:isDisabled="!isValid"
				@clicked="submit"
			/>
		</template>
	</VOverlay>
</template>

<script>
import { getStoreAction } from '@assets/scripts/store/config';
import Helpers from '@assets/scripts/helpers';
import { trimFlowName } from '@modules/FlowBuilder/components/flow';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import usePermission from '@assets/scripts/composables/usePermission';
import {
	GET_REST_APIS,
	CHECK_FOR_EXISTING_REST_METHOD_FLOW,
} from '@modules/FlowBuilder/endpoints';
import { log } from '@assets/scripts/components/notifications';

export default {
	name: 'NewFlow',
	data: function () {
		return {
			methodOptions: [
				{
					label: this.$t('general.methods.post'),
					id: 'post',
				},
				{
					label: this.$t('general.methods.get'),
					id: 'get',
				},
			],
			methodValue: false,
			isScripting: false,
			apiOptions: [],
			apiValue: false,
			flowNameValue: false,
			restMethodFlowExist: true,
			trimFlowName,
		};
	},
	computed: {
		/**
		 * Boolean to indicate whether the current
		 * user can create special flows, like Script Service,
		 * or using PUT or DELETE method
		 */
		canEditSpecialFlow: function () {
			return usePermission('Upsert special flow', 'FlowBuilder');
		},
		isValid: function () {
			return this.methodValue && this.apiValue && this.flowNameValue.trim();
		},
	},
	mounted: async function () {
		// get all available api's
		this.apiOptions = await useApiAsync(GET_REST_APIS);

		// format api options to use in VSelect component
		if (this.apiOptions.length > 0) {
			this.apiOptions = Helpers.mapObjectArrayForSelect(this.apiOptions, {
				key: 'guid',
				text: 'name',
			});
		}

		if (this.canEditSpecialFlow) {
			this.methodOptions.push({
				label: this.$t('general.methods.put'),
				id: 'put',
			});
			this.methodOptions.push({
				label: this.$t('general.methods.delete'),
				id: 'delete',
			});
		}
	},
	methods: {
		overlayClosed: function () {
			this.$store.dispatch(
				getStoreAction('TOGGLE_CREATE_NEW', 'FB'),
				false
			);
		},
		checkIfRestMethodFlowExist: async function () {
			// check if flow already exist with the same name + method + api
			// by making an api call to check
			this.restMethodFlowExist = await useApiAsync(
				CHECK_FOR_EXISTING_REST_METHOD_FLOW,
				{
					parameters: {
						method: this.methodValue,
						api_guid: this.apiValue,
						flow_name: this.flowNameValue,
						flow_guid: false,
					},
				}
			);
			if (this.restMethodFlowExist) {
				// show error message
				log(
					this.$t('fb.flowErrors.restMethodFlowExist'),
					'danger'
				);
			}
		},
		submit: async function () {
			await this.checkIfRestMethodFlowExist();
			// if not valid or flow already exist with the same name + method + api Display error/warning
			if (!this.isValid || this.restMethodFlowExist) return;

			this.$store.dispatch(getStoreAction('NEW_FLOW', 'FLOW'), {
				method: this.methodValue.toUpperCase(),
				api: this.apiValue,
				api_name: this.getApiName(),
				name: this.flowNameValue.trim(),
				is_script: this.isScripting,
			});

			this.overlayClosed();
		},
		getApiName: function () {
			if (this.apiOptions.length < 1 || !this.apiValue) return false;

			let result = false;

			this.apiOptions.some((row) => {
				if (row.value === this.apiValue) {
					result = row.text;
					return true;
				}
			});

			return result;
		},
	},
};
</script>
