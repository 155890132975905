<template>
	<div class="add-new-field">
		<FieldConfigMain
			:fields="fields"
			:existingFields="existingFields"
			v-model:valueModel="field"
			v-model:nameValid="nameValid"
			:showTypeInput="false"
			:showSaveButton="true"
			:saveButtonText="$t('fieldConfig.addNewButton')"
			:editMode="editMode"
			:parents="parents"
			@submit="addField"
			context="flow"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoreGetter, getStoreAction } from '@assets/scripts/store/config';
import {
	addBlockAppendFieldMeta,
	startBlockInputFieldMeta,
} from '@modules/FlowBuilder/endpoints';
import Field from '@assets/scripts/components/field';

export default {
	name: 'AddNewField',
	props: {
		blockType: {
			type: null,
		},
	},
	data: function () {
		return {
			nameValid: false,
			field: Field.createNew(
				{},
				this.blockType === 'add'
					? addBlockAppendFieldMeta
					: startBlockInputFieldMeta,
				true
			),
			fieldGetter:
				this.blockType === 'add'
					? 'ADD_BLOCK_FIELDS'
					: 'START_BLOCK_FIELDS',
			fieldAction:
				this.blockType === 'add'
					? 'ADD_APPEND_FIELD'
					: 'ADD_START_FIELD',
		};
	},
	computed: {
		...mapGetters({
			/**
			 * Get block that is currently viewed/edited
			 */
			block: getStoreGetter('CURRENT_BLOCK', 'BLOCKS'),
			/**
			 * Boolean to indicate if flow is in Edit mode
			 */
			editMode: getStoreGetter('EDIT_MODE', 'FLOW'),
			/**
			 * Get all parent fields for current block
			 */
			parents: getStoreGetter(
				'PARENT_FIELDS_FROM_CURRENT_BLOCK',
				'BLOCKS'
			),
		}),
		fields: function () {
			/**
			 * Get block fields of block that is currently viewed/edited
			 */
			return this.$store.getters[
				getStoreGetter(this.fieldGetter, 'BLOCKS')
			];
		},
		existingFields: function () {
			if (this.blockType === 'add') {
				// get calculated input for block
				return this.$store.getters[
					getStoreGetter('BLOCK_INPUT', 'BLOCKS')
				](this.block.guid);
			}

			return [];
		},
	},
	methods: {
		addField: function () {
			if (this.nameValid) {
				this.$store.dispatch(
					getStoreAction(this.fieldAction, 'BLOCKS'),
					Field.getName(this.field)
				);

				// reset values after submit
				this.field = Field.createNew(
					{},
					startBlockInputFieldMeta,
					true
				);
			}
		},
	},
};
</script>
