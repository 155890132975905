<template>
	<div
		class="content-header has-padding has-bottom-divider is-flex is-flex-grow-0 is-align-items-flex-end"
	>
		<VField
			:label="$t('fb.blockDetails.readBlock.chooseConnection')"
			:isFloating="true"
			:class="{ 'is-active': config.conn_guid }"
			class="has-padding-right-1"
		>
			<VSelect
				:options="connectionOptions"
				v-model="config.conn_guid"
				@change="() => connectionUpdated()"
				:disabled="!editMode"
			/>
		</VField>
		<VField
			:label="$t('fb.blockDetails.readBlock.chooseAction')"
			:isFloating="true"
			class="has-margin-right-3"
			:class="{ 'is-active': config.guid }"
		>
			<VSelectWithSearch
				v-model:modelValue="config.guid"
				:options="actionOptions"
				@update:modelValue="() => actionUpdated()"
				:disabled="!editMode || !config.conn_guid"
				:placeholder="config.guid ? `${config.name} - ${config.method}` : ''"
				:searchPlaceholder="$t('vueSelect.placeholder')"
			/>
		</VField>
		<div
			class="has-margin-bottom-05 is-flex is-align-items-flex-end"
			v-if="!isSPDocument"
		>
			<VOption
				v-model:modelValue="firstMatchOnly"
				type="checkbox"
				:label="$t('fb.blockDetails.readBlock.firstMatchOnly')"
				wrapperClasses="has-margin-0 has-margin-right-05"
				@update:modelValue="firstMatchOnlyChanged"
				:disabled="!editMode"
			/>
			<VTooltip
				:text="
					$t('fb.blockDetails.readBlock.firstMatchOnlyExplanation')
				"
				class="has-margin-right-3"
			/>
		</div>
	</div>

	<div class="scrollable-content">
		<div
			class="spaced-content has-margin-top-2 has-margin-bottom-075 has-bottom-divider is-flex is-flex-grow-0 is-align-items-flex-end"
		>
			<VTitle
				:text="$t('fb.blockDetails.readBlock.mapping')"
				:size="3"
				class="has-margin-bottom-1 has-margin-right-3"
			/>

			<VSearch v-model="search" />
		</div>
		<SortableDataTable
			:hoverable="false"
			:stickyHeader="false"
			:scrollable="false"
			defaultSort="to"
			:data="filteredData"
			:columns="columns"
			:emptyText="$t('fb.blockDetails.writeBlock.table.noResults')"
		>
			<template
				v-for="(row, key) in tableFields"
				:key="key"
				#[`from-${key}`]
			>
				<template v-if="editMode">
					<VField
						:label="$t('fb.blockDetails.writeBlock.field')"
						:isFloating="true"
						:class="{
							'is-active': config.mapping[key]['from'],
						}"
						v-if="config.mapping[key]"
					>
						<VSelect
							:options="fieldOptions(row.type, blockInput)"
							:hasUnsetOption="true"
							v-model="config.mapping[key]['from']"
							@change="mappingUpdated"
						/>
					</VField>
				</template>
				<template v-else>
					{{
						getNameAsPath(config.mapping[key]['from']) ||
						$t('general.dash')
					}}
				</template>
			</template>
		</SortableDataTable>
		<div
			class="spaced-content has-padding-bottom-1 has-margin-top-2 has-margin-bottom-075 has-bottom-divider is-flex is-flex-grow-0 is-align-items-flex-end"
			v-if="!isSPDocument"
		>
			<VTitle
				:text="$t('fb.blockDetails.readBlock.filtering')"
				:size="3"
				class="has-margin-bottom-0 has-margin-right-3"
			/>

			<VLink
				:text="$t('fb.blockDetails.readBlock.addFilterGroup')"
				icon="plus"
				v-if="editMode"
				:hasUnderline="true"
				class="has-margin-right-3 has-margin-left-auto"
				@click.prevent.stop="addFilterGroup"
				:isDisabled="currentAction === false"
			/>
		</div>

		<div class="spaced-content" v-if="filterTables.length > 0 && !isSPDocument" >
			<template
				v-for="(filterTableFields, key) in filterTables"
				:key="key"
			>
				<div
					class="filter-group has-background-grey-light has-radius-large has-padding-075"
				>
					<div
						class="has-margin-bottom-1 is-flex is-flex-grow-0 is-align-items-center"
					>
						<VLink
							:text="$t('fb.blockDetails.readBlock.addCondition')"
							icon="plus"
							v-if="editMode"
							:hasUnderline="true"
							class="has-margin-right-3"
							@click.prevent.stop="addFilterClicked(key)"
						/>

						<VButton
							v-if="editMode"
							@click.prevent.stop="deleteFilterGroup(key)"
							href=""
							:title="$t('general.delete')"
							:isTool="true"
							class="has-margin-left-auto"
							icon="delete"
							tabindex="-1"
						/>
					</div>
					<SortableDataTable
						:hoverable="false"
						:scrollable="false"
						:stickyHeader="false"
						tableClasses="table-without-padding"
						:data="filterTableFields"
						defaultSort=""
						:columns="filteringColumns"
						:emptyText="
							$t(
								'fb.blockDetails.readBlock.filteringTable.noResults'
							)
						"
					/>
				</div>

				<div v-if="key < filterTables.length - 1">
					<VTitle
						class="has-text-centered is-uppercase has-margin-top-2 has-margin-bottom-2"
						:text="$t('general.or')"
						:size="3"
					/>
				</div>
			</template>
		</div>
		<div v-else-if="!isSPDocument" class="has-text-centered">
			{{ $t('fb.blockDetails.readBlock.noFilters') }}
		</div>

		<div
			class="spaced-content has-padding-bottom-1 has-margin-top-2 has-margin-bottom-075 has-bottom-divider is-flex is-flex-grow-0 is-align-items-flex-end"
			v-if="!isSPDocument"
		>
			<VTitle
				:text="$t('fb.blockDetails.readBlock.sortOrder')"
				:size="3"
				class="has-margin-bottom-0 has-margin-right-3"
			/>

			<VLink
				v-if="editMode"
				:text="$t('fb.blockDetails.readBlock.chooseField')"
				icon="list"
				:hasUnderline="true"
				class="has-margin-right-3 has-margin-left-auto"
				@click.prevent.stop="openActionSortingFieldsList"
				:isDisabled="currentAction === false"
			/>
		</div>

		<BTable
			narrowed
			default-sort="order"
			default-sort-direction="ASC"
			:data="sortingTableFields"
			:draggable="editMode"
			@dragstart="sortingTableDragStart"
			@dragover="sortingTableDragOver"
			@dragleave="sortingTableDragLeave"
			@drop="sortingTableDrop"
			v-if="!isSPDocument"
		>
			<BTableColumn
				v-for="(column, index) in sortingColumns"
				v-bind:key="index"
				:width="column.width || null"
				:label="column.label"
				:field="column.field"
				:sortable="column.sortable"
				:cellClass="column.cellClass"
				:customSort="column.customSort || null"
				:visible="column.visible"
				v-slot="props"
			>
				<div>
					<template v-if="column.field === 'direction' && editMode">
						<VField
							:label="
								$t(
									'fb.blockDetails.readBlock.sortingTable.columns.direction'
								)
							"
							:isFloating="true"
							:class="{
								'is-active':
									config.sorting[props.row.key]['direction'],
							}"
							v-if="config.sorting[props.row.key]"
						>
							<VSelect
								:options="sortingOptions"
								v-model="
									config.sorting[props.row.key]['direction']
								"
								@change="sortingUpdated"
							/>
						</VField>
					</template>
					<template v-else>
						<template v-if="column.component">
							<Component
								:is="column.component"
								v-bind="column.args"
								@click.prevent.stop="column.click(props.row)"
								v-if="props.row[column.field]"
							/>
							<template v-else>&nbsp;</template>
						</template>
						<template v-else>
							{{ props.row[column.field] || column.default }}
						</template>
					</template>
				</div>
			</BTableColumn>

			<template #empty>
				<div class="has-text-centered">
					{{ $t('fb.blockDetails.readBlock.sortingTable.noResults') }}
				</div>
			</template>
		</BTable>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import { getBlockConfig } from '@modules/FlowBuilder/components/block';
import {
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { composeDisplayName } from '@modules/FlowBuilder/components/actions';
import { getOperatorName } from '@modules/FlowBuilder/components/filtering';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import {
	GET_ACTIONS,
	GET_ACTION_BY_ID,
 } from '@modules/FlowBuilder/endpoints';
 import { log } from '@assets/scripts/components/notifications';
 import { GET_CONNECTIONS } from '@assets/scripts/api/config';

export default {
	name: 'ReadBlockConfig',
	props: {
		description: {
			type: null,
		},
		preprocess: {
			type: null
		},
	},
	data: function () {
		return {
			connectionOptions: [],
			search: '',
			availableActions: [],
			currentAction: false,
			isSPDocument: false,
			firstMatchOnly: false,
			blockUpdated: false,
			mappingCache: {},
			sortingCache: {},
			filterCache: {},
			fieldOptions: Field.createFieldOptions,
			sortingColumns: [
				{
					label: '',
					field: 'order',
					sortable: true,
					searchable: false,
					visible: false,
					default: '',
				},
				{
					label: this.$t(
						'fb.blockDetails.readBlock.sortingTable.columns.field'
					),
					field: 'field',
					sortable: false,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t(
						'fb.blockDetails.readBlock.sortingTable.columns.direction'
					),
					field: 'direction',
					sortable: false,
					default: this.$t('general.dash'),
				},
				{
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
						tabindex: -1,
					},
					click: this.deleteSortingClicked,
				},
			],
			sortingOptions: [
				{
					text: this.$t('general.ascending'),
					value: 'ASC',
				},
				{
					text: this.$t('general.descending'),
					value: 'DESC',
				},
			],
			draggingRow: null,
			draggingRowIndex: null,
		};
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate if flow is in Edit mode
			 */
			editMode: getStoreGetter('EDIT_MODE', 'FLOW'),
			/**
			 * Get block that is currently viewed/edited
			 */
			block: getStoreGetter('CURRENT_BLOCK', 'BLOCKS'),
		}),
		/**
		 * Determine current available input fields for the block
		 * These fields come from the Flow State
		 */
		blockInput: function () {
			if (!this.block) return [];

			// get calculated input for block
			return this.$store.getters[
				getStoreGetter('BLOCK_INPUT', 'BLOCKS')
			](this.block.guid);
		},
		/**
		 * Get desctructured config of block
		 */
		config: function () {
			return getBlockConfig(this.block);
		},
		columns: function () {
			// definition of table columns
			const columns = [
				{
					label: this.$t('fb.functions.mapping.columns.systemField'),
					field: 'to',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('fb.functions.mapping.columns.flowField'),
					field: 'from',
					sortable: false,
					searchable: false,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('fb.functions.mapping.columns.type'),
					field: 'field_type',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('fb.functions.mapping.columns.required'),
					field: 'is_required',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
			];

			// add is_key column if Action Document choosen
			if (!this.isSPDocument) {
				columns.push({
					label: this.$t('fb.functions.mapping.columns.key'),
					field: 'is_key',
					sortable: true,
					searchable: false,
					default: this.$t('general.dash'),
				});
			}

			return columns;
		},
		tableFields: function () {
			const rows = [];

			if (!this.currentAction) return rows;

			const addRow = (row, index) => {
				rows.push({
					to: Field.getNameAsPath(row.name),
					from:
						this.config.mapping[index] &&
						this.config.mapping[index]['from']
							? this.fieldNameViewOnly(
									this.config.mapping[index]['from']
							  )
							: this.$t('general.dash'),
					type: row.type,
					field_type: Field.translateVarType(row.type),
					is_key: row.key
						? this.$t('general.yes')
						: this.$t('general.no'),
					is_required: row.validation.required
						? this.$t('general.yes')
						: this.$t('general.no'),
					key: index,
				});
			}

			// loop over input fields
			this.currentAction.input.forEach((row, index) => {
				if(this.isSPDocument || (row.key || row.search)) {
					// add all fields to table for SP,
					// but only add key and/or search fields to table for Action
					addRow(row, index);
				}
			});

			return rows;
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// return all if no search entered
			if (this.search.length === 0) return this.tableFields;

			// filter on search string
			return Helpers.filterByString(
				this.tableFields,
				this.search,
				this.searchableCols
			);
		},
		sortingTableFields: function () {
			const rows = [];

			if (!this.currentAction) return rows;

			// loop over input fields for Action
			this.config.sorting.forEach((row, index) => {
				rows.push({
					order: row.order,
					field: Field.getNameAsPath(row.field),
					direction: this.editMode ?
						row.direction :
						( row.direction ?
							this.$t('general.' + row.direction.toLowerCase()) :
							this.$t('general.dash') 
						),
					delete: this.editMode,
					key: index,
				});
			});

			return rows;
		},
		filteringColumns() {
			return [
				{
					label: this.$t(
						'fb.blockDetails.readBlock.filteringTable.columns.field'
					),
					field: 'field',
					sortable: false,
					headerClass: 'has-padding-left-0',
					cellClass: 'has-padding-left-0',
					width: '25%',
					default: this.$t('general.dash'),
				},
				{
					label: this.$t(
						'fb.blockDetails.readBlock.filteringTable.columns.fieldType'
					),
					field: 'field_type',
					sortable: false,
					width: '15%',
					default: this.$t('general.dash'),
				},
				{
					label: this.$t(
						'fb.blockDetails.readBlock.filteringTable.columns.operator'
					),
					field: 'operator',
					sortable: false,
					width: '15%',
					default: this.$t('general.dash'),
				},
				{
					label: this.$t(
						'fb.blockDetails.readBlock.filteringTable.columns.type'
					),
					field: 'type',
					sortable: false,
					width: '15%',
					default: this.$t('general.dash'),
				},
				{
					label: this.$t(
						'fb.blockDetails.readBlock.filteringTable.columns.value'
					),
					field: 'value',
					sortable: false,
					width: '30%',
					default: this.$t('general.dash'),
				},
				{
					label: '',
					field: 'edit',
					sortable: false,
					width: '0%',
					default: '',
					component: 'VButton',
					headerClass: 'has-padding-right-0',
					cellClass: 'is-button-tool has-padding-right-0',
					args: {
						href: '',
						title: this.editMode
							? this.$t('general.edit')
							: this.$t('general.inspect'),
						isTool: true,
						class: '',
						icon: this.editMode ? 'edit' : 'eye',
					},
					click: this.inspectFilterClicked,
				},
				{
					label: '',
					field: 'delete',
					sortable: false,
					width: '0%',
					default: '',
					component: 'VButton',
					headerClass: 'has-padding-right-0',
					cellClass: 'is-button-tool has-padding-right-0',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
					},
					click: this.deleteFilterClicked,
				},
			];
		},
		filterTables() {
			const result = [];

			this.config.filters.forEach((group, groupKey) => {
				const groupRows = [];

				group.rules.forEach((rule, index) => {
					groupRows.push({
						group: groupKey,
						field: Field.getNameAsPath(rule.name),
						field_type: Field.translateVarType(rule.type),
						operator: getOperatorName(rule.operator),
						type: rule.rule_type
							? this.$t(
									'fb.conditions.' + rule.rule_type.toLowerCase()
							  )
							: false,
						value: (rule.type === 'String' || rule.type === 'Number') && rule.rule_type === "Dynamic" ? rule.value.map(value => Field.getNameAsPath(value)).join(', '): rule.value.join(', '),
						edit: this.editMode,
						delete: this.editMode,
						key: index,
					});
				});

				result.push(groupRows);
			});

			return result;
		},
		actionOptions: function () {
			if (!this.availableActions || this.availableActions.length < 1)
				return [];

			const options = [];

			this.availableActions.forEach((row) => {
				options.push({
					guid: row.guid,
					name: composeDisplayName(row),
				});
			});

			// map available actions into list for VSelect
			return Helpers.mapObjectArrayForSelect(options, {
				key: 'guid',
				text: 'name',
			});
		},
	},
	mounted: async function () {
		// get all available connections
		const connections = await useApiAsync(GET_CONNECTIONS);

		if (connections && connections.length > 0) {
			// map Connection objects to use in select field
			this.connectionOptions = Helpers.mapObjectArrayForSelect(connections, {
				key: 'conn_guid',
				text: 'name',
			})
		}

		// configuration options for read block
		this.firstMatchOnly = this.config.settings.first_match;

		// load chosen action meta
		await this.getAction();

		// get available Read actions
		await this.getAndSetAvailableActions()

		this.setMappingCache();

		this.setSortingCache();

		this.setFilterCache();

		this.actionUpdated(true);
	},
	methods: {
		getAndSetAvailableActions: async function () {
			if (!this.config.conn_guid) {
				// if no connection guid do not do the api call and set availableActions to an empty array
				this.availableActions = [];
				return;
			}
			// get available Read actions
			const actions = await useApiAsync(GET_ACTIONS, {
				keys: {
					connection: this.config.conn_guid
				},
				parameters: {
					read: true
				}
			});
			
			this.availableActions = actions? actions : []
		},
		getNameAsPath: function (input) {
			return Field.getNameAsPath(input);
		},
		markBlockAsUpdated: function () {
			// no need to trigger this mutation more than once
			if (this.blockUpdated) return;

			// mark block as modified whenever a change to
			// the fields is made
			this.$store.commit(
				getStoreMutation('BLOCK_CONFIG_MODIFIED', 'BLOCKS')
			);

			this.blockUpdated = true;
		},
		actionUpdated: async function (initial = false) {
			// load chosen action meta
			if (!initial) await this.getAction();

			// set mapping and sorting for new action
			this.setMapping();

			// set auto mapping when user change the action documnet
			if (!initial) this.setAutoMapping();

			this.setSorting();
			this.setFilters();

			// get some data from chosen action to copy to block config
			const { method, name, obj_guid, type } = this.currentAction || {};
			Object.assign(this.config, { method, name, obj_guid, type });

			if (!initial) this.markBlockAsUpdated();
		},
		firstMatchOnlyChanged() {
			this.config.settings.first_match = this.firstMatchOnly;
			this.markBlockAsUpdated();
		},
		connectionUpdated: async function () {
			// reset chosen action values
			Object.assign(this.config, { method: '', name: '', obj_guid: '', type: '', guid: null });
			this.currentAction = false

			// get available Read actions
			await this.getAndSetAvailableActions()

			this.markBlockAsUpdated();
		},
		getAction: async function () {
			if (!this.config.guid) {
				// unset current action and return
				this.currentAction = false;
				return;
			}

			// load current action from nominow api
			const action = await useApiAsync(GET_ACTION_BY_ID, {
				keys: {
					guid: this.config.guid
				}
			});

			this.currentAction = action || false;
			this.isSPDocument = this.currentAction.type === 'ConnectionMethod';
			const { conn_guid } = this.currentAction || {};
			Object.assign(this.config, { conn_guid });
		},
		fieldNameViewOnly: function (name) {
			if (!name) return this.$t('general.dash');
			return Field.getNameAsPath(name);
		},
		// select the field option that have the same name and type 
		// of the document field automatically
		setAutoMapping: function () {
			const autoMappingCounter = Field.setAutoMapping(this.currentAction.input, this.config.mapping, this.blockInput);
			if (autoMappingCounter > 0) {
				// log the amount of fields that are auto mapped
				log(this.$t('info.autoMapping', { count: autoMappingCounter }), 'info');
			}
		},
		setMapping: function () {
			this.config.mapping = [];

			if (
				this.currentAction &&
				this.currentAction.input &&
				this.currentAction.input.length > 0
			) {
				this.currentAction.input.forEach((row) => {
					// only add key and/or search fields to mapping
					if (!row.key && !row.search && !this.isSPDocument) return;
					this.config.mapping.push(
						this.getFromMappingCache(row.name)
					);
				});
			}
		},
		setMappingCache: function () {
			if (!this.config.guid) return;

			if (!this.mappingCache[this.config.guid]) {
				this.mappingCache[this.config.guid] = [];
			}

			// update cache, used when switching between Actions
			this.mappingCache[this.config.guid] = Helpers.cloneObject(
				this.config.mapping
			);
		},
		mappingUpdated: function () {
			this.setMappingCache();
			this.markBlockAsUpdated();
		},
		getFromMappingCache: function (name) {
			const result = {
				to: name,
				from: '',
			};

			if (!this.config.guid || !this.mappingCache[this.config.guid])
				return result;

			if (this.mappingCache[this.config.guid]) {
				const cached = this.mappingCache[this.config.guid].filter(
					(row) => row.to === name
				);

				if (cached.length > 0) {
					result.from = cached[0].from || '';
				}
			}

			return result;
		},
		openActionSortingFieldsList: function () {
			// open drawer with fields to choose from
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'actionSortingFields',
				data: {
					action: this.currentAction,
					callback: this.addSortingFields,
					current: this.config.sorting || [],
				},
			});
		},
		addSortingFields(fields) {
			// get count of current sorting fields
			const count = this.config.sorting.length;

			// loop over new fields
			fields.forEach((field, i) => {
				// add new field to sorting fields
				this.config.sorting.push({
					field: field.name,
					direction: 'ASC', // sort ASC by default
					order: count + i + 1,
				});
			});

			this.sortingUpdated();
		},
		deleteSortingClicked({ order, key }) {
			this.config.sorting.splice(key, 1);

			// after deleting a row, all remaining sorting rows
			// need to update their 'order' to make sure there are no
			// gaps between them

			// loop over all sorting rules
			this.config.sorting.forEach((row) => {
				// check if current row was ordered lower than
				// deleted row (ordered lower means a higher 'order')
				if (row.order > order) {
					// decrease position by 1
					row.order--;
				}
			});

			this.sortingUpdated();
		},
		// called when dragging in sorting table starts
		sortingTableDragStart(payload) {
			this.draggingRow = payload.row;
			this.draggingRowIndex = payload.index;
		},
		// called when row in sorting table is dragged over another row
		sortingTableDragOver(payload) {
			payload.event.target.closest('tr').classList.add('is-selected');
			payload.event.preventDefault();
		},
		sortingTableDragLeave(payload) {
			payload.event.target.closest('tr').classList.remove('is-selected');
			payload.event.preventDefault();
		},
		// called when dragged row in sorting table is dropped in new position
		sortingTableDrop(payload) {
			payload.event.target.closest('tr').classList.remove('is-selected');
			const droppedOnRowIndex = payload.index;

			// update sort order in block config
			this.updateSortOrder(
				this.draggingRow.key,
				this.draggingRowIndex + 1,
				droppedOnRowIndex + 1
			);
		},
		updateSortOrder(key, oldPos, newPos) {
			// do nothing if new position is same as
			// old position
			if (oldPos === newPos) return;

			// check if row was moved either up or down
			const movedUp = oldPos > newPos;

			// loop over all sorting rules
			this.config.sorting.forEach((row, index) => {
				// set moved row to new position
				if (index === key) {
					row.order = newPos;
				} else if (
					// check if moved row was moved up, and
					// current row was sorted above the old position
					// and below or equal to the new position
					movedUp &&
					row.order < oldPos &&
					row.order >= newPos
				) {
					// increase position by 1
					row.order++;
				} else if (
					// check if moved row was moved down, and
					// current row was sorted below the old position
					// and above or equal to the new position
					!movedUp &&
					row.order > oldPos &&
					row.order <= newPos
				) {
					// decrease position by 1
					row.order--;
				}
			});

			this.sortingUpdated();
		},
		setSorting: function () {
			this.config.sorting = [];

			if (this.currentAction) {
				this.config.sorting = this.getFromSortingCache();
			}
		},
		setSortingCache: function () {
			if (!this.config.guid) return;

			if (!this.sortingCache[this.config.guid]) {
				this.sortingCache[this.config.guid] = [];
			}

			// update cache, used when switching between Actions
			this.sortingCache[this.config.guid] = Helpers.cloneObject(
				this.config.sorting
			);
		},
		sortingUpdated: function () {
			this.setSortingCache();
			this.markBlockAsUpdated();
		},
		getFromSortingCache: function () {
			if (!this.config.guid || !this.sortingCache[this.config.guid])
				return [];

			return this.sortingCache[this.config.guid];
		},
		setFilters: function () {
			this.config.filters = [];

			if (this.currentAction) {
				this.config.filters = this.getFromFilterCache();
			}
		},
		setFilterCache: function () {
			if (!this.config.guid) return;

			if (!this.filterCache[this.config.guid]) {
				this.filterCache[this.config.guid] = [];
			}

			// update cache, used when switching between Actions
			this.filterCache[this.config.guid] = Helpers.cloneObject(
				this.config.filters
			);
		},
		filtersUpdated: function () {
			this.setFilterCache();
			this.markBlockAsUpdated();
		},
		getFromFilterCache: function () {
			if (!this.config.guid || !this.filterCache[this.config.guid])
				return [];

			return this.filterCache[this.config.guid];
		},
		addFilterGroup() {
			if (
				!this.config.filters ||
				typeof this.config.filters !== 'object'
			) {
				this.config.filters = [];
			}

			this.config.filters.push({
				rules: [],
			});

			this.filtersUpdated();
		},
		deleteFilterGroup(key) {
			const group = this.config.filters[key] || false;

			if (!group) return;

			const deleteGroup = () => {
				this.config.filters.splice(key, 1);
				this.filtersUpdated();
			};

			if (group.rules && group.rules.length > 0) {
				// ask confirmation to delete group
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t(
						'fb.blockDetails.readBlock.filterGroupDelete.confirm.title'
					),
					body: this.$t(
						'fb.blockDetails.readBlock.filterGroupDelete.confirm.body'
					),
					confirmButtonText: this.$t(
						'fb.blockDetails.readBlock.filterGroupDelete.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// do delete after confirmation
						deleteGroup();
					},
				});
			} else {
				deleteGroup();
			}
		},
		inspectFilterClicked({ group, key }) {
			const filter = this.getFilter(group, key);

			if (filter) {
				this.openReadFilterDrawer(filter);
			}
		},
		addFilterClicked(group) {
			const filterGroup = this.config.filters[group] || false;

			if (!filterGroup || !filterGroup.rules) return;

			this.openReadFilterDrawer({}, filterGroup);
		},
		getFilter(group, key) {
			const filterGroup = this.config.filters[group] || false;

			if (!filterGroup || !filterGroup.rules) return false;

			return filterGroup.rules[key] || false;
		},
		openReadFilterDrawer(filter = {}, filterGroup = false) {
			// open drawer to add/edit filter
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'readFilter',
				data: {
					fields: this.currentAction.output || [],
					dynamic: this.blockInput || [],
					filter,
					callback: (updatedFilter) => {
						Object.assign(filter, updatedFilter);

						// add as new filter to given group
						if (filterGroup) {
							filterGroup.rules.push(filter);
						}

						this.filtersUpdated();
					},
				},
			});
		},
		deleteFilterClicked({ group, key }) {
			const filterGroup = this.config.filters[group] || false;

			const filter = this.getFilter(group, key);
			if (!filter) return;

			// ask confirmation to delete filter
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t(
					'fb.blockDetails.readBlock.filterDelete.confirm.title'
				),
				body: this.$t(
					'fb.blockDetails.readBlock.filterDelete.confirm.body'
				),
				confirmButtonText: this.$t(
					'fb.blockDetails.readBlock.filterDelete.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// do delete after confirmation
					filterGroup.rules.splice(key, 1);
					this.filtersUpdated();
				},
			});
		},
	},
};
</script>
