<template>
	<div class="config-block-details spaced-content is-flex-grow-1 is-flex is-flex-direction-column">
		<VField
			:isFloating="true"
			:label="$t('fb.blockDetails.configBlock.functionGuid')"
			:class="{ 'is-active': config.function_guid }"
		>
			<VInput
				v-model="config.function_guid"
				type="text"
				@update:modelValue="markModified"
				:placeholder="$t('fb.blockDetails.configBlock.functionGuid')"
				:disabled="!editMode"
			/>
		</VField>
		<VField
			:isFloating="true"
			:label="$t('fb.blockDetails.configBlock.type')"
			:class="{ 'is-active': config.type }"
		>
			<VInput
				v-model="config.type"
				type="text"
				@update:modelValue="markModified"
				:placeholder="$t('fb.blockDetails.configBlock.type')"
				:disabled="!editMode"
			/>
		</VField>
		<VField
			:isFloating="true"
			:label="$t('fb.blockDetails.configBlock.className')"
			:class="{ 'is-active': config.class_name }"
		>
			<VInput
				v-model="config.class_name"
				type="text"
				@update:modelValue="markModified"
				:placeholder="$t('fb.blockDetails.configBlock.className')"
				:disabled="!editMode"
			/>
		</VField>
		<VField
			:isFloating="true"
			:label="$t('fb.blockDetails.configBlock.functionName')"
			:class="{ 'is-active': config.function_name }"
		>
			<VInput
				v-model="config.function_name"
				type="text"
				@update:modelValue="markModified"
				:placeholder="$t('fb.blockDetails.configBlock.functionName')"
				:disabled="!editMode"
			/>
		</VField>
		<VField
			:isFloating="true"
			:label="$t('fb.blockDetails.configBlock.objectGuid')"
			:class="{
				'is-active': config.object_guid,
			}"
		>
			<VInput
				v-model="config.object_guid"
				type="text"
				@update:modelValue="markModified"
				:placeholder="$t('fb.blockDetails.configBlock.objectGuid')"
				:disabled="!editMode"
			/>
		</VField>
		<VField
			:isFloating="true"
			:label="$t('fb.blockDetails.configBlock.guid')"
			:class="{ 'is-active': config.guid }"
		>
			<VInput
				v-model="config.guid"
				type="text"
				@update:modelValue="markModified"
				:placeholder="$t('fb.blockDetails.configBlock.guid')"
				:disabled="!editMode"
			/>
		</VField>
		<div v-for="(field, name) in jsonFields" :key="name">
			<VField
				v-if="editMode"
				:label="$t(`fb.blockDetails.configBlock.${name}`)"
				class="is-fullwidth is-flex-grow-1 is-flex is-flex-direction-column"
			>
				<VTextarea
					v-model="field.string"
					:placeholder="$t('fb.blockDetails.configBlock.pasteJson')"
					@update:modelValue="jsonModified(name)"
					class="has-code"
				/>
			</VField>
			<VField
				v-else
				:label="$t(`fb.blockDetails.configBlock.${name}`)"
				class="is-fullwidth is-flex-grow-1 is-flex is-flex-direction-column has-margin-bottom-4"
			>
				<pre><code>{{ field.string }}</code></pre>
			</VField>
			<VNotification
				v-if="!field.valid"
				:text="$t('fb.blockDetails.configBlock.enterValidJson')"
				:isFullwidth="true"
				:hasClose="false"
				type="danger"
			/>

			<VButton
				v-if="editMode"
				:text="$t('fb.blockDetails.configBlock.formatJson')"
				variant="is-secondary"
				@click.prevent.stop="formatTextarea(name)"
				:disabled="!field.valid"
				class="format-json-button"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getBlockConfig } from '@modules/FlowBuilder/components/block';
import { getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';
import { log } from '@assets/scripts/components/notifications';

export default {
	name: 'ConfigBlockConfig',
	props: {
		preprocess: {
			type: null
		},
	},
	data: function () {
		return {
			jsonFields: {
				properties: {
					string: '',
					json: {},
					valid: false,
				},
				input_mapping: {
					string: '',
					json: {},
					valid: false,
				},
				config: {
					string: '',
					json: {},
					valid: false,
				},
			},
			modified: false,
		};
	},
	mounted: function () {
		for (const name in this.jsonFields) {
			this.jsonFields[name].json = this.config[name] || {};
			this.stringifyJson(name);
			this.checkIfJsonFieldsAreValid(name);
		}

		if (this.editMode) {
			// set preprocess function to execute when
			// current block is saved
			this.$emit(
				'update:preprocess',
				this.preprocessBeforeSave
			);
		}
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate if flow is in Edit mode
			 */
			editMode: getStoreGetter('EDIT_MODE', 'FLOW'),
			/**
			 * Get block that is currently viewed/edited
			 */
			block: getStoreGetter('CURRENT_BLOCK', 'BLOCKS'),
		}),
		/**
		 * Get desctructured config of block
		 */
		config: function () {
			return getBlockConfig(this.block);
		},
		isValid: function () {
			for (const name in this.jsonFields) {
				if (!this.jsonFields[name].valid) {
					return false;
				}
			}
			return true;
		},
	},
	methods: {
		stringifyJson: function (name) {
			try {
				this.jsonFields[name].string = JSON.stringify(this.jsonFields[name].json, null, 4);
				if (this.jsonFields[name].string === '{}') this.jsonFields[name].string = '';
			} catch (e) {
				log('Unable to stringify', 'danger', this.jsonFields[name].json, false);
			}
		},
		parseJson: function (name) {
			if (this.jsonFields[name].valid && this.jsonFields[name].string !== '') {
				this.jsonFields[name].json = JSON.parse(this.jsonFields[name].string);
			}
		},
		formatTextarea: function (name) {
			if (!this.jsonFields[name].valid) return;
			this.parseJson(name);
			this.stringifyJson(name);
		},
		jsonModified: function (name) {
			this.markModified();
			this.checkIfJsonFieldsAreValid(name);

		},
		markModified: function () {
			if (this.modified) return;

			this.modified = true;

			// mark block as modified whenever a change to
			// the fields is made
			this.$store.commit(
				getStoreMutation('BLOCK_CONFIG_MODIFIED', 'BLOCKS')
			);
		},
		checkIfJsonFieldsAreValid: function (name) {
			if (!this.jsonFields[name].string) return this.jsonFields[name].valid = true;

			// check if value can be parsed as JSON
			try {
				JSON.parse(this.jsonFields[name].string);
			} catch (e) {
				return this.jsonFields[name].valid = false;
			}

			return this.jsonFields[name].valid = true;
		},
		preprocessBeforeSave: function () {
			if (this.isValid) {
				for (const name in this.jsonFields) {
					this.parseJson(name);
					this.config[name] = this.jsonFields[name].json;
				}
			} else {
				// show error about unsaved config
				log(this.$t('fb.blockDetails.configBlock.jsonNotSaved'), 'danger');
			}
		},
	},
};
</script>
