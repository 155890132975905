<template>
	<div
		class="content-header has-padding has-bottom-divider is-flex is-flex-grow-0 is-align-items-center"
	>
		<VLink
			v-if="editMode"
			:text="$t('fb.blockDetails.resultBlock.chooseField')"
			icon="list"
			:hasUnderline="true"
			@click.prevent.stop="openResultFieldList"
			:isDisabled="fullOutput"
			class="has-margin-right-3"
		/>

		<VOption
			v-model:modelValue="isArray"
			type="checkbox"
			:label="$t('fb.blockDetails.resultBlock.isArray')"
			wrapperClasses="has-margin-0 has-margin-right-05"
			@update:modelValue="isArrayChanged"
			:disabled="!editMode"
		/>
		<VTooltip
			:text="$t('fb.blockDetails.resultBlock.isArrayExplanation')"
			class="has-margin-right-3"
		/>

		<VOption
			v-model:modelValue="isIgnore"
			type="checkbox"
			:label="$t('fb.blockDetails.resultBlock.isIgnore')"
			wrapperClasses="has-margin-0 has-margin-right-05"
			@update:modelValue="isIgnoreChanged"
			:disabled="!editMode"
		/>
		<VTooltip
			:text="$t('fb.blockDetails.resultBlock.isIgnoreExplanation')"
			class="has-margin-right-3"
		/>

		<VOption
			v-model:modelValue="fullOutput"
			type="checkbox"
			:label="$t('fb.blockDetails.resultBlock.fullOutput')"
			wrapperClasses="has-margin-0 has-margin-right-05"
			@update:modelValue="fullOutputChanged"
			:disabled="!editMode"
		/>
		<VTooltip
			:text="$t('fb.blockDetails.resultBlock.fullOutputExplanation')"
			class="has-margin-right-3"
		/>

		<VSearch v-model="search" />
	</div>

	<SortableDataTable
		:hoverable="false"
		defaultSort="field_name"
		:data="filteredData"
		:columns="columns"
		:emptyText="emptyMessage"
		tableClasses="scrollable-content"
	>
		<template
			v-for="(row, key) in tableFields"
			:key="key"
			#[`alias-${key}`]
		>
			<template v-if="editMode">
				<VField
					icon="edit"
					:iconOnRight="true"
					:isError="row.duplicate || false"
				>
					<VInput
						v-model="output[key].alias"
						:iconOnRight="true"
						@update:modelValue="markBlockAsUpdated"
					/>
				</VField>

				<VNotification
					v-if="row.duplicate || false"
					type="danger"
					:text="$t('fb.blockDetails.resultBlock.duplicateError')"
				/>
			</template>
			<template v-else>
				{{
					getNameAsPath(output[key].alias) || $t('general.dash')
				}}
			</template>
		</template>
	</SortableDataTable>
</template>

<script>
import { mapGetters } from 'vuex';
import Helpers from '@assets/scripts/helpers';
import {
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import Field from '@assets/scripts/components/field';

export default {
	name: 'ResultBlockConfig',
	props: {
		description: {
			type: null,
		},
		preprocess: {
			type: null
		},
	},
	data: function () {
		return {
			search: '',
			fields: [],
			isArray: false,
			isIgnore: false,
			fullOutput: false,
			blockUpdated: false,
		};
	},
	mounted: function () {
		// all fields that are available to output in result block
		this.fields = this.block.output;

		// configuration options for result block
		this.isArray = this.block.config.is_array;
		this.isIgnore = this.block.config.ignore_nulls;
		this.fullOutput = this.block.config.full_output;

		if (this.editMode) {
			// set preprocess function to execute when
			// current block is saved
			this.$emit(
				'update:preprocess',
				this.preprocessBeforeSave
			);
		}
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate if flow is in Edit mode
			 */
			editMode: getStoreGetter('EDIT_MODE', 'FLOW'),
			/**
			 * Get result block fields of block that is currently viewed/edited
			 */
			output: getStoreGetter('RESULT_BLOCK_FIELDS', 'BLOCKS'),
			/**
			 * Get block that is currently viewed/edited
			 */
			block: getStoreGetter('CURRENT_BLOCK', 'BLOCKS'),
		}),
		columns: function () {
			// definition of table columns
			const columns = [
				{
					label: this.$t('field.name'),
					field: 'field_name',
					width: '30%',
					sortable: true,
					searchable: true,
					leveled: true,
					default: this.$t('general.dash'),
					customSort: this.sortByFieldName,
				},
				{
					label: this.$t('field.type'),
					field: 'field_type',
					width: '15%',
					sortable: false,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.alias'),
					field: 'alias',
					sortable: false,
					searchable: true,
					default: this.$t('general.dash'),
				},
			];

			// add delete column if applicable
			if (this.editMode) {
				columns.push({
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
						tabindex: -1,
					},
					click: this.deleteClicked,
				});
			}

			return columns;
		},
		tableFields: function () {
			const rows = [];

			if (!this.output || this.output.length < 1) return rows;

			this.output.forEach((field, index) => {
				rows.push({
					sort_name: field.name,
					field_name: Field.getChildName(field.name),
					field_type: Field.getTranslatedVarTypeForField(
						field.name,
						this.fields
					),
					alias: field.alias,
					delete: this.editMode,
					level: Field.getFieldLevel(field.name),
					key: index,
					duplicate: this.getSiblings(index).some(
						(sibling) =>
							sibling.alias.toLowerCase() ===
							field.alias.toLowerCase()
					),
				});
			});

			return rows;
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			if (this.fullOutput) return [];

			// filter on search string
			const matches = Helpers.filterByString(
				this.tableFields,
				this.search,
				this.searchableCols
			);

			// also add ancestors of matches to matches
			if (matches.length < this.tableFields.length) {
				Field.addAncestorsOfMatches(
					matches,
					this.tableFields,
					'sort_name'
				);
			}

			return matches;
		},
		emptyMessage: function () {
			if (this.fullOutput) return this.$t('fb.blockDetails.resultBlock.fullOutputOn');
			return this.$t('fb.blockDetails.startBlock.table.noResults');
		},
		fieldCount: function () {
			return this.output.length || 0;
		},
	},
	methods: {
		getNameAsPath: function (input) {
			return Field.getNameAsPath(input);
		},
		deleteClicked({ key }) {
			// get correct text to display in confirmation popup
			const confirmText = this.rowHasChildren(key)
				? this.$t(
						'fb.blockDetails.resultBlock.delete.confirm.bodyMultiple'
				  )
				: this.$t('fb.blockDetails.resultBlock.delete.confirm.body');

			// ask confirmation to delete field
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('fb.blockDetails.resultBlock.delete.confirm.title'),
				body: confirmText,
				confirmButtonText: this.$t(
					'fb.blockDetails.resultBlock.delete.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// do delete after confirmation
					this.deleteRow(key);
					this.markBlockAsUpdated();
				},
			});
		},
		getSiblings(key) {
			if (!this.output[key]) return [];

			// get field
			const field = this.output[key];

			// get siblings
			return Field.getSiblings(field.name, this.output);
		},
		getRowChildren(key) {
			if (!this.output[key]) return [];

			// get field
			const field = this.output[key];

			return Field.getDescendants(field.name, this.output);
		},
		rowHasChildren(key) {
			return this.getRowChildren(key).length > 0;
		},
		deleteRow(key) {
			const deleteField = (field) => {
				this.output.splice(this.output.indexOf(field), 1);
			};

			const field = this.output[key];

			// also delete all children of the field
			this.getRowChildren(key).forEach((child) => {
				deleteField(child);
			});

			deleteField(field);
		},
		markBlockAsUpdated: function () {
			// no need to trigger this mutation more than once
			if (this.blockUpdated) return;

			// mark block as modified whenever a change to
			// the fields is made
			this.$store.commit(
				getStoreMutation('BLOCK_CONFIG_MODIFIED', 'BLOCKS')
			);

			this.blockUpdated = true;
		},
		isArrayChanged() {
			this.block.config.is_array = this.isArray;
			this.markBlockAsUpdated();
		},
		isIgnoreChanged() {
			this.block.config.ignore_nulls = this.isIgnore;
			this.markBlockAsUpdated();
		},
		fullOutputChanged() {
			this.block.config.full_output = this.fullOutput;
			this.markBlockAsUpdated();
		},
		setDescription() {
			this.$emit(
				'update:description',
				this.$t('fb.blockDetails.description.result', {
					count: this.fieldCount,
				})
			);
		},
		sortByFieldName(a, b, isAsc) {
			return Field.orderParentChildList(a.sort_name, b.sort_name, isAsc);
		},
		openResultFieldList: function () {
			// open drawer with fields to choose from
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'resultFields',
			});
		},
		preprocessBeforeSave: function () {
			if (this.fullOutput) {
				// unset output of result block if FullOutput
				// option is enabled
				this.$store.commit(getStoreMutation('RESET_RESULT_BLOCK_OUTPUT', 'BLOCKS'));
			}
		},
	},
	watch: {
		fieldCount() {
			this.setDescription();
		},
	},
};
</script>
